const fetchOutputModels = async ({ commit, dispatch }) => {
  commit("SET_FETCH_OUTPUT_MODELS_LOADING", false);
  try {
    const response = await window.axios.get("/proxy/get-connectors-outputs");
    if (!response || !response.data.success) {
      throw new Error(response.data.message);
    }
    commit("SET_OUTPUT_MODELS", response.data.available_actions);
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    commit("SET_FETCH_OUTPUT_MODELS_LOADING", false);
  }
};

export default fetchOutputModels;
