import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  patient: null,
  patientCard: {
    date: null,
    exp_month: null,
    exp_year: null,
    id: null,
    lastfour: null,
    type: null,
  },
  patientsList: [],
  autoPayment: {},
  messages: {},
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
