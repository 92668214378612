const deleteDevice = async ({ commit, dispatch }, { uid }) => {
  commit('REMOVE_DEVICE_LOADING', true);

  try {
    const response = await window.axios.delete(`/proxy/device-manager?device_id=${uid}`);
  
    if (response && response.data.success) {
      commit('REMOVE_DEVICE', { uid });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('REMOVE_DEVICE_LOADING', false);
  }
};

export default deleteDevice;
