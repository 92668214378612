const fetchInvoiceDetails = async (
  { commit, rootGetters, dispatch },
  options
) => {
  commit('SET_FETCH_INVOICE_DETAILS_LOADING', true);
  commit('SET_INVOICE_DETAILS', {});
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    const { invoiceId, patient_id } = options;
    let url = `/proxy/invoices/${invoiceId}`;
    url += `?customer_id=${customer_id}&patient_id=${patient_id}`;

    const response = await window.axios.get(url);

    if (response && response.data.success) {
      commit('SET_INVOICE_DETAILS', response.data.result);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_FETCH_INVOICE_DETAILS_LOADING', false);
  }
};

export default fetchInvoiceDetails;
