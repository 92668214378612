const getters = {
  AUTOMATION_WORKFLOWS: ({ automationWorkflows }) => automationWorkflows,
  AUTOMATION_WORKFLOWS_COUNT: ({ workflowsCount }) => workflowsCount,
  FETCH_AUTOMATION_WORKFLOWS_LOADING: ({ fetchAutomationWorkflowLoading }) =>
    fetchAutomationWorkflowLoading,
  EDIT_AUTOMATION_WORKFLOW_LOADING: ({ editWorkflowLoading }) => (id) =>
    editWorkflowLoading[id],
  DELETE_AUTOMATION_WORKFLOW_LOADING: ({ deleteWorkflowLoading }) =>
    deleteWorkflowLoading,
  AUTOMATION_WORKFLOW_HISTORY_LOADING: ({ workflowHistoryLoading }) =>
    workflowHistoryLoading,
  AUTOMATION_WORKFLOW_HISTORY: ({ automationWorkflowHistory }) => (id) =>
    automationWorkflowHistory[id] || { history: [], hasMore: false },
  WORKFLOW_OWNER_LOADING: ({ setWorkflowOwnerLoading }) => (id) =>
    setWorkflowOwnerLoading[id],
  SELECTED_AUTOMATION_WORKFLOW: ({ selectedAutomationWorkflow }) =>
    selectedAutomationWorkflow,
  SELECTED_AUTOMATION_WORKFLOW_LOADING: ({
    selectedAutomationWorkflowLoading,
  }) => selectedAutomationWorkflowLoading,
  UPDATE_AUTOMATION_WORKFLOW_LOADING: ({ updateAutomationWorkflowLoading }) =>
    updateAutomationWorkflowLoading,
  PERFORM_GOOGLE_ACTION: ({ performingGoogleAction }) => performingGoogleAction,
  PERFORM_OUTLOOK_ACTION: ({ performingOutlookAction }) =>
    performingOutlookAction,
  PERFORM_TWILIO_ACTION: ({ performingTwilioAction }) => performingTwilioAction,
  PERFORM_SFTP_ACTION: ({ performingSFTPAction }) => performingSFTPAction,
  AUTOMATION_WORKFLOWS_NAMES: ({ automationWorkflowsNames }) => automationWorkflowsNames,
  FETCH_AUTOMATION_WORKFLOWS_NAMES_LOADING: ({fetchAutomationWorkflowNamesLoading}) => fetchAutomationWorkflowNamesLoading,
};

export default getters;
