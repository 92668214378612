import { getAction } from '@/util/get-connectors';

const AUTOMATION_WORKFLOW_HISTORY_LOADING = ({ fetchWorkflowHistoryLoading }) =>
  fetchWorkflowHistoryLoading;

const AUTOMATION_WORKFLOW_HISTORY = ({ workflowHistory }) =>
  workflowHistory || [];

const HAS_MORE = ({ hasMore }) => hasMore;

const AUTOMATION_WORKFLOW_HISTORY_IO =
  ({ workflowStatus }) =>
    (storageId) =>
      workflowStatus[storageId] || {};

const AUTOMATION_WORKFLOW_HISTORY_CONNECTOR_LIST = (state) => (storageId) =>
  (state.workflowHistoryConnectors[storageId] || []).map((connector) => {
    const { icon, color } = getAction(connector.action_type);
    return {
      ...connector,
      icon,
      color,
    };
  });

const CONNECTOR_DETAILS = (state) => (task_id) =>
  state.workflowHistoryConnectorDetails[task_id] || {};

const WORKFLOW_HISTORY_LOADING = ({ fetchWorkflowHistoryLoading }) =>
  fetchWorkflowHistoryLoading;

const FETCH_WORKFLOW_HISTORY_CONNECTOR_LIST_LOADING = ({
  fetchWorkflowHistoryConnectorsList,
}) => fetchWorkflowHistoryConnectorsList;

const CONNECTOR_DETAILS_LOADING = ({ fetchconnectorDetailsLoading }) =>
  fetchconnectorDetailsLoading;

const RESUME_WORKFLOW_LOADING = ({ resumeWorkflowLoading }) =>
  resumeWorkflowLoading;

const FAILED_TASKS = ({ failedTasks }) =>
  failedTasks;

const FAILED_TASKS_LOADING = ({ failedTasksLoading }) =>
  failedTasksLoading;

const RETRIED_TASKS = ({ retriedTasks }) =>
  retriedTasks;

const RETRIED_TASKS_LOADING = ({ retriedTasksLoading }) =>
  retriedTasksLoading;

const WORKFLOW_HISTORY_ITEM = ({ workflowHistoryItem }) => workflowHistoryItem

const UPDATE_WORKFLOW_STATS_LOADING = ({updateWorkflowStatsLoading}) => updateWorkflowStatsLoading;

export default {
  HAS_MORE,
  CONNECTOR_DETAILS,
  WORKFLOW_HISTORY_LOADING,
  AUTOMATION_WORKFLOW_HISTORY,
  AUTOMATION_WORKFLOW_HISTORY_IO,
  AUTOMATION_WORKFLOW_HISTORY_LOADING,
  AUTOMATION_WORKFLOW_HISTORY_CONNECTOR_LIST,
  FETCH_WORKFLOW_HISTORY_CONNECTOR_LIST_LOADING,
  CONNECTOR_DETAILS_LOADING,
  RESUME_WORKFLOW_LOADING,
  FAILED_TASKS,
  FAILED_TASKS_LOADING,
  RETRIED_TASKS,
  RETRIED_TASKS_LOADING,
  WORKFLOW_HISTORY_ITEM,
  UPDATE_WORKFLOW_STATS_LOADING
};
