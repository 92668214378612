import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  authentications: [],

  workflowsUsingCredentialToDelete: [],

  // loaders
  createCredentialsLoading: false,
  editCredentialsLoading: false,
  deleteCredentialsLoading: false,
  fetchCredentialsLoading: false,
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
