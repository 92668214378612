const getWorkflowHistoryConnectorsList = async ({ commit, rootGetters, dispatch }, { storage_id }) => {
  commit('SET_WORKFLOW_HISTORY_CONNECTOR_LIST_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = '/proxy/connector-list';

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post(url, { storage_id, customer_id });

    if (response && response.data.success) {
      commit('SET_HISTORY_CONNECTOR_LIST', { storageId: storage_id, values: response.data.values });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_WORKFLOW_HISTORY_CONNECTOR_LIST_LOADING', false);
  }
};

export default getWorkflowHistoryConnectorsList;
