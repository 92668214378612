export const workflowStatus = {
  NOT_STARTED: 'NOT_STARTED',
  SKIPPED: 'SKIPPED',
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  PAUSED: 'PAUSED',
  CANCELLED: 'CANCELLED',
};

export const defaultRunning = {
  runningInProductionMode: false,
  workflow_status: workflowStatus.NOT_STARTED,
  vm_status: workflowStatus.NOT_STARTED,
  validation: {
    validation_status: workflowStatus.NOT_STARTED,
    actions: workflowStatus.NOT_STARTED,
    schedule: workflowStatus.NOT_STARTED,
  },
  actions_statuses: {},
  PAUSE_UPDATES: false,
  storageId: null,
};
