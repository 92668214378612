const getPatientMessages = async (
  { commit, rootGetters, dispatch },
  options
) => {
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const { patient_id } = options;
    const data = { customer_id, pid: patient_id };
    const response = await window.axios.post(
      '/proxy/get-patient-messages',
      data
    );

    if (response && response.data.success) {
      commit('SET_PATIENT_MESSAGES', {
        patient_id,
        messages: response.data.output?.messages,
      });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  }
};

export default getPatientMessages;
