export default {
  SET_WEBHOOKS: (state, payload) => {
    state.webhooks = payload;
  },
  SET_WEBHOOKS_TRIGGERS: (state, payload) => {
    state.webhooksTriggers = payload;
  },

  ADD_UPDATE_WEBHOOK_LOADING: (state, payload) => {
    state.addUpdateWebhookLoading = payload;
  },

  REMOVE_WEBHOOK: (state, { id }) => {
    state.webhooks = state.webhooks.filter(webhook => webhook.id !== id);
  },
  SET_WEBHOOKS_LOADING: (state, payload) => {
    state.webhooksLoading = payload;
  },
  REMOVE_WEBHOOK_LOADING: (state, payload) => {
    state.removeWebhookLoading = payload;
  },
};
