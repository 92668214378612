const saveNewCard = async ({ commit, rootGetters, dispatch }, options) => {
  commit('SET_SAVE_NEW_CARD_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let url = '/proxy/api/payment/save-card';

    let customer_id = customer.customer_id;
    const locID = customer?.CustomersFeature?.defaultLocation;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post(url, { ...options, locID });
    if (response && response.data.success) {
      dispatch(
        'notifications/pushNotification',
        response.data.message || 'Card Saved.',
        {
          root: true,
        }
      );
      dispatch(
        'invoices/getPatientCards',
        { pid: options.pid },
        { root: true }
      );
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_SAVE_NEW_CARD_LOADING', false);
  }
};

export default saveNewCard;
