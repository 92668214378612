import Vue from "vue";
import VueGapi from "vue-gapi";

const config = {
  apiKey: process.env.VUE_APP_GAPI_API_KEY,
  clientId: process.env.VUE_APP_GAPI_CLIENT_ID,
  discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  // see all available scopes here: https://developers.google.com/identity/protocols/googlescopes'
  scope: [
    "https://www.googleapis.com/auth/drive",
    "https://www.googleapis.com/auth/drive.file",
    "https://www.googleapis.com/auth/gmail.send",
    "https://www.googleapis.com/auth/gmail.labels",
    "https://www.googleapis.com/auth/gmail.readonly",
  ].join(" "),
  ux_mode: "prompt",
  refreshToken: true,
};

Vue.use(VueGapi, config);
