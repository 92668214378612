const getPaymentDetails = async (
  { commit, rootGetters, dispatch },
  options
) => {
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = { customer_id, pid: options.pid };
    const response = await window.axios.post(
      '/proxy/api/payment/get-payment-details',
      data
    );

    if (response && response.data.success) {
      commit('SET_PAYMENT_DETAILS', response.data);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  }
};

export default getPaymentDetails;
