import Cookies from 'js-cookie';

const getCustomerData = async ({ commit, dispatch }) => {
  commit('SET_CUSTOMER_DATA_LOADING', true);
  try {
    const { data } = await window.axios.post('/proxy/get-customer-data', {
      customerID: Cookies.get('customerId'),
      userID: Cookies.get('userId'),
    });

    if (data && data.success === true) {
      const { customer } = data;
      commit('SET_CUSTOMER', customer);
      commit('auth/SET_AUTH', true, { root: true });
    } else {
      throw data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_CUSTOMER_DATA_LOADING', false);
  }
};

export default getCustomerData;
