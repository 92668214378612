const addDevice = async ({ dispatch, rootGetters, commit }, data) => {
  commit('ADD_UPDATE_DEVICE_LOADING', true);

  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let customer_id = customer.customer_id;

    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post('/proxy/device-manager', {
      settings: data,
      customer_id,
    });

    if (response && response.data.success) {
      dispatch('clearSelectedDevice');
      dispatch('fetchDevices');
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('ADD_UPDATE_DEVICE_LOADING', false);
  }
};

export default addDevice;
