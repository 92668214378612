const fetchPaymentPlans = async ({ commit, rootGetters, dispatch }, options) => {
  commit('SET_FETCH_PAYMENT_PLAN_LOADING', true);
  commit('SET_PAYMENT_PLANS', []);
  commit('SET_PAYMENT_PLANS_TOTAL', 0);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let paymentPlansUrl = '/proxy/api/payment/payment-plans';
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    const { search, status, limit, page, sort, sortOrder } = options;
    let qs = `?customer_id=${customer_id}&search=${encodeURIComponent(search)}`;
    
    if (limit) qs += `&limit=${limit}`;
    if (page) qs += `&page=${page}`;
    if (status) qs += `&status=${status}`
    if (sort) {
      qs += `&sort=${sort}`
      qs += `&sortOrder=${sortOrder}`
    }

    const paymentPlansResponse = await window.axios.get(paymentPlansUrl + qs);

    if (paymentPlansResponse && paymentPlansResponse.data.success) {
      commit('SET_PAYMENT_PLANS', paymentPlansResponse.data.batches);
      commit('SET_PAYMENT_PLANS_TOTAL', paymentPlansResponse.data.count);
    } else {
      throw paymentPlansResponse.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_FETCH_PAYMENT_PLAN_LOADING', false);
  }
};

export default fetchPaymentPlans;
