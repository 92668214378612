const setWorkflowOwners = async (
  { commit, rootGetters, dispatch },
  { id, owners }
) => {
  commit('SET_WORKFLOW_OWNERS_LOADING', { id, loading: true });
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let url = '/proxy/set-workflow-owners';

    let customer_id = customer.customer_id;
    const data = { workflowId: id, owners };
    if (customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post(url, data);

    if (response && response.data.success) {
      // commit('UPDATE_AUTOMATION_WORKFLOW', { id, workflow: response.data.workflow })
      dispatch('fetchWorkflows');
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_WORKFLOW_OWNERS_LOADING', { id, loading: false });
  }
};

export default setWorkflowOwners;
