import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  invoices: [],
  unpaidInvoices: [],
  totalInvoicesCount: 0,
  invoiceDetails: {},
  cards: [],
  paymentDetails: null,
  autoPayment: null,
  balance: null,
  activities: [],
  paymentPlans: [],
  paymentPlansTotal: 0,
  standaloneInvoices: [],
  standaloneInvoicesTotal: 0,

  // loaders
  fetchInvoicesLoading: false,
  fetchInvoiceDetailsLoading: false,
  chargeUpdateLoading: false,
  sendReminderLoading: false,
  fetchCardsLoading: false,
  deleteCardLoading: null, // when deleting, holds id of the card being deleted
  primaryCardLoading: false,
  saveNewCardLoading: false,
  savePaymentPlanLoading: false,
  fetchActivitiesLoading: false,
  fetchPaymentPlanLoading: false,
  toggleBatchStatusLoading: false,
  createPaymentPlanLoading: false,
  standaloneInvoicesLoading: false,
  sendBalanceTransactionLoading: false,
  createInvoiceLoading: false,
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
