import createAuthentication from "./createAuthentication";
import deleteAuthentication from './deleteAuthentication';
import fetchAuthentications from "./fetchAuthentications";
import updateAuthentication from './updateAuthentication';
import clearWorkflowsUsingCredentials from './clearWorkflowsUsingCredentials';

export default {
  createAuthentication,
  deleteAuthentication,
  fetchAuthentications,
  updateAuthentication,
  clearWorkflowsUsingCredentials,
};
