import addDevice from './addDevice';
import deleteDevice from './deleteDevice';
import fetchDevices from './fetchDevices';
import updateDevice from './updateDevice';
import fetchDeviceStatus from './fetchDeviceStatus';
import fetchDeviceDetails from './fetchDeviceDetails';
import clearSelectedDevice from './clearSelectedDevice';

export default {
  addDevice,
  deleteDevice,
  fetchDevices,
  updateDevice,
  fetchDeviceStatus,
  fetchDeviceDetails,
  clearSelectedDevice,
};
