export const createWebhook = async ({ dispatch, commit, rootGetters }, webhookData) => {
  commit('ADD_UPDATE_WEBHOOK_LOADING', true);

  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    if (customer.customer_id === 0) {
      webhookData.customer_id = selected_customer.customer_id;
    }
    const response = await window.axios.post('/proxy/webhooks', webhookData);

    if (response && response.data.success) {
      dispatch('fetchWebhooks');
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('ADD_UPDATE_WEBHOOK_LOADING', false);
  }
};
