import cloneDeep from 'lodash/cloneDeep';
import { workflowStatus } from '@/util/workflow-statuses';

const checkVmStatus = async (
  { rootGetters, getters, commit },
  { workflowId }
) => {
  const nodes = getters['NODES'];
  const running = cloneDeep(getters['WORKFLOW_RUNNING_OBJ'](workflowId));
  const customer = rootGetters['customer/CUSTOMER'];

  // check vm connection for update_patient_notes update_patient_plan update_patient_notes_and_plan
  let checkVm = false;

  function check(nodes) {
    if (checkVm) return;

    nodes.forEach((node) => {
      if (checkVm) return;
      const type = node.action_type;
      if (
        type === 'update_patient_notes' ||
        type === 'update_patient_plan' ||
        type === 'update_patient_notes_and_plan'
      ) {
        checkVm = node.settings.device_id;
      } else if (type === 'loop') {
        check(node.settings.cycleBody);
      } else if (type === 'try_catch') {
        check(node.settings.tryCatchBody);
      }
      else if (type === 'conditional') {
        check(node.settings.false);
        check(node.settings.true);
      }
    });
  }

  check(nodes);

  if (checkVm) {
    running.vm_status = workflowStatus.PENDING;
    try {
      const queryString = `customer_id=${customer.id}&device_id=${checkVm}`;
      const resp = await window.axios.get(
        `/proxy/device-manager/?${queryString}`
      );

      if (resp.data && resp.data.success) {
        running.vm_status = workflowStatus.SUCCESS;
      } else {
        running.vm_status = workflowStatus.FAILURE;
        running.workflow_status = workflowStatus.FAILURE;
      }
    } catch (error) {
      console.log(error, 'error chec vm ==>');
      running.vm_status = workflowStatus.FAILURE;
      running.workflow_status = workflowStatus.FAILURE;
    }
  } else {
    running.vm_status = workflowStatus.SKIPPED;
  }

  if (running.vm_status === workflowStatus.FAILURE) {
    return (running.workflow_status = workflowStatus.FAILURE);
  }
  commit('SET_WORKFLOW_RUNNING_OBJ', { ...running, workflowId });
};

export default checkVmStatus;
