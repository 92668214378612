const getWorkflowTriggerIO = async ({ commit, rootGetters, dispatch }, payload) => {
    commit('SET_FETCH_AUTOMATION_WORKFLOWS_NAMES_LOADING', true);
    try {
        const customer = rootGetters['customer/CUSTOMER'];
        const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

        let url = `/proxy/get-trigger-io/${payload.workflow_id}`;

        let customer_id = customer.customer_id;

        if (customer.customer_id === 0) {
            customer_id = selected_customer.customer_id;
        }
        if (payload.customer_id) {
            customer_id = payload.customer_id;
        }
        url += `?customer_id=${customer_id}`;

        const data = {
            customer_id,
            ...payload,
        };

        const response = await window.axios.get(url, data);
        if(response?.data?.success !== undefined && response?.data?.success === false) {
            throw response.data
        }
        if (response) {
            return response.data;
        }
    } catch (error) {
        dispatch('notifications/setError', error.message, { root: true });
    } finally {
        // stuff here
        commit('SET_FETCH_AUTOMATION_WORKFLOWS_NAMES_LOADING', false);
    }
};

export default getWorkflowTriggerIO;
