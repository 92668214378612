const fetchInvoices = async ({ commit, rootGetters, dispatch }, options) => {
  commit('SET_FETCH_INVOICES_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let url = '/proxy/invoices';
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    const {
      page,
      limit,
      search,
      isPaid,
      fromDate,
      toDate,
      expand,
      patientId,
      filter,
      sort,
      sortOrder,
    } = options;
    url += `?customer_id=${customer_id}&page=${page}&limit=${limit}`;

    if (search) url += `&search=${search}`; // search is a string (patient name
    if (isPaid !== undefined && isPaid !== null) url += `&isPaid=${isPaid}`;
    if (fromDate) url += `&fromDate=${fromDate}`;
    if (toDate) url += `&toDate=${toDate}`;
    if (expand) url += `&expand=${expand}`;
    if (patientId) url += `&patientId=${patientId}`;
    if (filter) url += `&filter=${filter}`;
    if (sort) {
      url += `&sort=${sort}`;
      url += `&sortOrder=${sortOrder}`;
    }

    const response = await window.axios.get(url);

    if (response && response.data.success) {
      commit('SET_INVOICES', response.data.invoices?.rows);
      commit('SET_TOTAL_INVOICES_COUNT', response.data.invoices?.count);
      commit('SET_UNPAID_INVOICES', response.data.invoices?.unpaidInvoices);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_FETCH_INVOICES_LOADING', false);
  }
};

export default fetchInvoices;
