const passwordReset = async ({ commit }, { email }) => {
  commit('SET_IS_AUTHENTICATING', true);
  commit('SET_AUTHENTICATION_ERROR', false);
  try {
    const response = await window.axios.post('/proxy/reset-password', {
      email,
    });
    if (response.data && response.data.success === true) {
      commit('SET_SHOW_PASSWORD_RESET_DIALOG', true);
    } else if (response.data && response.data.message) {
      throw { message: response.data.message };
    }
  } catch (error) {
    commit('SET_AUTHENTICATION_ERROR', true);
    commit('SET_AUTHENTICATION_MESSAGE', error.message || 'Unknown error');
  } finally {
    commit('SET_IS_AUTHENTICATING', false);
  }
};

export default passwordReset;
