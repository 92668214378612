const deleteCustomerUsers = async ({ commit, getters, rootGetters, dispatch }, { userId }) => {
  commit('SET_DELETE_CUSTOMER_USERS_LOADING', true);

  try {
    const customer = getters['CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    const data = {
      user_id: userId,
    };

    if (customer && customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.delete("/proxy/delete-user", {
      data,
    });

    if (response.data.success) {
      dispatch('getCustomerUsers');
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    // somethng here
    commit('SET_DELETE_CUSTOMER_USERS_LOADING', false);
  }
};

export default deleteCustomerUsers;
