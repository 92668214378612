const getPatientBalance = async ({ commit, rootGetters, dispatch, state }) => {
  try {
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = {
      customer_id,
      pid: state.patient.patient_id,
    };

    const response = await window.axios.post(
      "/proxy/get-patient-balance",
      data
    );

    if (response && response.data.success) {
      commit("ADD_PATIENT_BALANCE", response.data.balanceInfo);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  }
};

export default getPatientBalance;
