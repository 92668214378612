const getRetriedTasks = async ({ commit, dispatch }, { storageId }) => {
  commit('SET_RETRIED_TASKS_LOADING', true);
  try {

    let url = `/proxy/workflows/${storageId}/retried-tasks`;

    const response = await window.axios.get(url);

    if (response && response.data.success) {
      commit('SET_RETRIED_TASKS', response.data.retriedTasks);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_RETRIED_TASKS_LOADING', false);
  }
};

export default getRetriedTasks;
