import Vue from 'vue';

const mutations = {
  ADD_PROMT: (state, payload) => {
    state.promts = [
      ...state.promts,
      {
        ...payload,
        count: payload.workflowIds ? payload.workflowIds.length : 0,
      },
    ];
  },
  SET_PROMT: (state, payload) => {
    if(!payload.attachments) {
      payload.attachments = [];
    }
    if(!payload.tools) {
      payload.tools = [];
    }
    state.promt = payload;
  },
  SET_PROMTS: (state, payload) => {
    state.promts = payload.map((item) => ({
      ...item,
      count: item.workflowIds?.length || 0,
    }));
  },
  DELETE_PROMT: (state, id) => {
    state.promts = state.promts.filter((item) => item.id !== id);
  },
  SET_CREATE_PROMT_LOADING: (state, payload) => {
    state.createPromtLoading = payload;
  },
  SET_EDIT_PROMT_LOADING: (state, payload) => {
    state.editPromtLoading = payload;
  },
  SET_DELETE_PROMT_LOADING: (state, payload) => {
    state.deletePromtLoading = payload;
  },
  SET_FETCH_PROMT_LOADING: (state, payload) => {
    state.fetchPromtLoading = payload;
  },
  SET_FETCH_PROMTS_LOADING: (state, payload) => {
    state.fetchPromtsLoading = payload;
  },
  SET_RUNNING_PROMT: (state, payload) => {
    state.runningPromt = payload;
  },
  SET_INPUTS: (state, payload) => {
    state.promt.inputs = payload;
  },
  SET_ATTACHMENTS: (state, payload) => {
    state.promt.attachments = payload;
  },
  SET_OUTPUT: (state, payload) => {
    if (payload.action) {
      state.output = payload.action.output;
    }
  },
  SET_PROMT_LOGS: (state, payload) => {
    Vue.set(state.promt, 'logs', payload);
  },
};

export default mutations;
