const mutations = {
  ADD_AUTHENTICATION_ITEM: (state, payload) => {
    state.authentications = [...state.authentications, payload];
  },
  SET_AUTHENTICATIONS: (state, payload) => {
    state.authentications = payload;
  },
  DELETE_AUTHENTICATION: (state, id) => {
    state.authentications = state.authentications.filter(item => item.id !== id)
  },
  UPDATE_AUTHENTICATION: (state, { id, credentials }) => {
    state.authentications = state.authentications.map(item => {
      if (item.id === id) {
        return {
          ...item,
          ...credentials
        };
      }

      return item;
    })
  },
  SET_CREATE_CREDENTIALS_LOADING: (state, payload) => {
    state.createCredentialsLoading = payload;
  },
  SET_EDIT_CREDENTIALS_LOADING: (state, payload) => {
    state.editCredentialsLoading = payload;
  },
  SET_DELETE_CREDENTIALS_LOADING: (state, payload) => {
    state.deleteCredentialsLoading = payload;
  },
  SET_FETCH_CREDENTIALS_LOADING: (state, payload) => {
    state.fetchCredentialsLoading = payload;
  },
  SET_WORKFLOWS_USING_CREDENTIALS: (state, payload) => {
    state.workflowsUsingCredentialToDelete = payload;
  },
};

export default mutations;
