import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { getAutomationInitialSettings } from "@/util/browserAutomationEvents";

const state = () => ({
  promts: [],
  promt: {
    settings: {
      automation: getAutomationInitialSettings(),
    },
    inputs: [],
    attachments: [],
  },
  output:'',

  // loaders
  createPromtLoading: false,
  editPromtLoading: false,
  deletePromtLoading: false,
  fetchPromtLoading: false,
  fetchPromtsLoading: false,
  runningPromt: false,
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
