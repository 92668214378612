const sendReminder = async ({ commit, rootGetters, dispatch }, options) => {
  commit('SET_SEND_REMINDER_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let url = '/proxy/notify-by-phone';

    let customer_name = customer.CustomerName;
    if (customer.customer_id === 0) {
      customer_name = selected_customer.CustomerName;
    }
    const { phone, message } = options;
    const response = await window.axios.post(url, {
      customer_name,
      phone,
      message,
    });

    if (response?.data?.success) {
      dispatch('notifications/pushNotification', 'Link sent successfully.', {
        root: true,
      });
    } else {
      throw response.data;
    }
    return { success: true };
  } catch (error) {
    const message = error.error || error.message || 'Something went wrong';
    dispatch('notifications/setError', message, { root: true });
    return { success: false };
  } finally {
    commit('SET_SEND_REMINDER_LOADING', false);
  }
};

export default sendReminder;
