import cloneDeep from "lodash/cloneDeep";
import { findNode } from "@/util/action-types";

const nodeDrop = ({ getters, dispatch }, { options }) => {
  const nodeId = getters["MOVING_NODE_ID"];
  const startSettings = getters["START_SETTINGS"];
  const nodeList = cloneDeep(getters["NODES"]);
  const selectedNodeOptions = getters["SELECTED_NODE_OPTIONS"];

  const node = findNode(nodeList, nodeId);

  const {
    index: selectedNodeIndex,
    parentNode: selectedNodeParentNode,
  } = selectedNodeOptions;

  const {
    index: insertIndex,
    parentNode: insertParentNode,
    branch: inserBranch,
  } = options;

  const isEventType =
    startSettings.trigger_type === "event" && startSettings.trigger_subtype;
  if (!selectedNodeParentNode && !selectedNodeIndex && isEventType) return;

  let sameIndex =
    selectedNodeIndex === insertIndex || selectedNodeIndex + 1 === insertIndex;

  let sameParent = false;

  if (selectedNodeParentNode && insertParentNode) {
    if (insertParentNode.action_type === "conditional") {
      sameParent =
        selectedNodeParentNode.id === insertParentNode.id &&
        node.path.branch === inserBranch;
    } else {
      sameParent = selectedNodeParentNode.id === insertParentNode.id;
    }
  }
  if (!(sameParent && sameIndex)) {
    dispatch("removeNode", node);
    dispatch("insertNode", { node, options });
  }
};

export default nodeDrop;
