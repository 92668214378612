import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  appTypes: [],
  contentPreloader: {
    show: false,
    text: '',
    fullScreen: false,
  },
  notifications: [],
  showDrawer: true,
  showHeader: true,
  showAddBotScreen: false,
  service_types: [],
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
