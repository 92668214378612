import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  workflowHistory: [],
  hasMore: false,
  workflowStatus: {},
  workflowHistoryConnectors: {
    // storage_id: [connectors]
  },
  workflowHistoryConnectorDetails: {
    // connector_id: {...details here}
  },

  failedTasks: [],
  failedTasksLoading: false,

  retriedTasks: [],
  retriedTasksLoading: false,

  // loaders
  fetchWorkflowHistoryLoading: false,
  fetchWorkflowHistoryConnectorsList: false,
  fetchconnectorDetailsLoading: false,
  resumeWorkflowLoading: false,
  updateWorkflowStatsLoading: false,
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
