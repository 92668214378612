import Cookies from "js-cookie";

const sendMessage = async ({ commit, rootGetters, dispatch }, payload) => {
    commit("SET_AI_RESPONSE_LOADING", true);
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload, userId: Cookies.get('userId'), customer_id: customer.customer_id };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        const response = await window.axios.post(`/proxy/ai/send-message`, data);
        if (response && response.data.success) {
            return response.data;
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    } finally {
        commit("SET_AI_RESPONSE_LOADING", false);
    }
};

export default sendMessage;