const recalcStats = async ({ rootGetters, dispatch, commit }, data) => {
    try {
        commit('SET_UPDATE_WORKFLOW_STATS_LOADING', true);

        const customer = rootGetters['customer/CUSTOMER'];
        const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
        if(!data.customer_id) {
            data.customer_id = customer.customer_id;
            if (customer.customer_id === 0) {
                data.customer_id = selected_customer.customer_id;
            }
        }

        const response = await window.axios.post('/proxy/update-workflow-stats', data);
        if (response && response.data.success) {
            dispatch('notifications/pushNotification', `Workflow stats has been updated`, {
                root: true,
            });
            commit('SET_UPDATE_WORKFLOW_STATS_LOADING', false);
            return response.data.report
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch('notifications/setError', error.message, { root: true });
    } finally {
        commit('SET_UPDATE_WORKFLOW_STATS_LOADING', false);
    }
};
export default recalcStats;
