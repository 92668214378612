const getters = {
  PROMTS: ({ promts }) => promts,
  PROMT: ({ promt }) => promt,
  OUTPUT: ({ output }) => output || {},
  CREATE_PROMT_LOADING: ({ createPromtLoading }) => createPromtLoading,
  EDIT_PROMT_LOADING: ({ editPromtLoading }) => editPromtLoading,
  DELETE_PROMT_LOADING: ({ deletePromtLoading }) => deletePromtLoading,
  FETCH_PROMT_LOADING: ({ fetchPromtLoading }) => fetchPromtLoading,
  FETCH_PROMTS_LOADING: ({ fetchPromtsLoading }) => fetchPromtsLoading,
  RUNNING_PROMT: ({ runningPromt }) => runningPromt,
  PROMT_SETTINGS: ({ promt }) => promt.settings || {},
  PROMT_INPUTS: ({ promt }) => promt.inputs || [],
  PROMPT_ATTACHMENTS: ({ promt }) =>  promt.attachments || [],
  PROMT_ACTION: ({ promt }) => promt.action || {},
  PROMT_LOGS: ({ promt }) => promt.logs || [],
};

export default getters;
