export const deleteWebhook = async ({ commit, dispatch, rootGetters }, { id }) => {
  commit('REMOVE_WEBHOOK_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];


    let customer_id = customer?.customer_id ?? customer?.user?.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.delete(`/proxy/webhooks/${id}/` + `?customer_id=${customer_id}`);
    if (response && response.data.success) {
      commit('REMOVE_WEBHOOK', { id });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('REMOVE_WEBHOOK_LOADING', false);
  }
};