<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: notificationsGetters, mapActions: notificationsActions } = createNamespacedHelpers('notifications');

export default {
  name: 'CWSNotification',
  computed: {
    ...notificationsGetters({
      error: 'ERROR', 
      notifications: 'NOTIFICATIONS',
    }),
  },
  watch: {
    error(newValue) {
      if (newValue) {
        this.$toastr.Add({
          type: 'error',
          msg: newValue,
          onClosed: this.onErrorClose,
        });
      }
    },
    notifications: {
      deep: true,
      handler(value) {
        const notifications = [...value];

        // TODO: refine this
        for (const notification of notifications) {
          this.$toastr.Add({
            msg: notification.message,
            onClosed: () => this.onNotificationClose(notification),
          });
        }
      },
    },
  },
  methods: {
    ...notificationsActions(['pushNotification', 'clearError', 'clearNotification']),
    onErrorClose() {
      this.clearError();
    },
    onNotificationClose(notification) {
      this.clearNotification(notification);
    }
  },
  render() {
    return null;
  }
}
</script>

<style>

</style>