import getTasks from './getTasks';
import getStorageStats from './getStorageStats';
import getWorkflowHistory from './getWorkflowHistory';
import getConnectorInputOutput from './getConnectorInputOutput';
import getWorkflowHistoryConnectorsList from './getWorkflowHistoryConnectorsList';
import resumeWorkflow from './resumeWorkflow';
import getFailedTasks from './getFailedTasks';
import getRetriedTasks from './getRetriedTasks';
import getScopeRetriedTasks from './getScopeRetriedTasks';
import stopWorkflow from "@/store/workflows/history/actions/stopWorkflow";
import getOneFromWorkflowHistory from "@/store/workflows/history/actions/getOneFromWorkflowHistory";
import recalcStats from "@/store/workflows/history/actions/recaclStats";

export default {
  getTasks,
  getStorageStats,
  getWorkflowHistory,
  getOneFromWorkflowHistory,
  getConnectorInputOutput,
  getWorkflowHistoryConnectorsList,
  resumeWorkflow,
  getFailedTasks,
  getRetriedTasks,
  getScopeRetriedTasks,
  stopWorkflow,
  recalcStats,
};
