export default {
  REMOVE_DEVICE: (state, { uid }) => {
    state.devices = state.devices.filter(device => device.uid !== uid);
  },
  SET_DEVICES: (state, payload) => {
    state.devices = payload;
  },
  ADD_UPDATE_DEVICE_LOADING: (state, payload) => {
    state.addUpdateDeviceLoading = payload;
  },
  SET_SELECTED_DEVICE: (state, payload) => {
    state.selectedDevice = payload;
  },
  SET_SELECTED_DEVICE_STATUS: (state, payload) => {
    state.selectedDeviceStatus = payload;
  },
  SET_DEVICES_LOADING: (state, payload) => {
    state.devicesLoading = payload;
  },
  SET_SELECTED_DEVICE_LOADING: (state, payload) => {
    state.selectedDeviceLoading = payload;
  },
  SET_SELECTED_DEVICE_STATUS_LOADING: (state, payload) => {
    state.selectedDeviceStatusLoading = payload;
  },
  REMOVE_DEVICE_LOADING: (state, payload) => {
    state.removeDeviceLoading = payload;
  },
};
