const createInvoice = async (
  { commit, rootGetters, dispatch },
  { data, dateRange }
) => {
  commit('SET_CREATE_INVOICE_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    let url = '/proxy/invoices/create-invoice';
    const response = await window.axios.post(url, { ...data, customer_id });

    if (!response || !response.data.success) {
      throw response.data;
    }

    const refetchData = {
      page: 1,
      limit: 10,
      expand: 'charges,all_unpaid',
      patientId: data.patient_id,
    };
    if (dateRange) {
      refetchData.fromDate = new Date(
        dateRange[0].setHours(23, 59, 59, 999)
      ).toISOString();
      refetchData.toDate = new Date(
        dateRange[1].setHours(23, 59, 59, 999)
      ).toISOString();
    }
    dispatch('fetchInvoices', refetchData);
    dispatch('notifications/pushNotification', 'Invoice created.', {
      root: true,
    });
    return response.data;
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_CREATE_INVOICE_LOADING', false);
  }
};

export default createInvoice;
