const deleteWorker = async ({ commit, rootGetters, dispatch }, workerId) => {
  commit("SET_DELETE_WORKER_LOADING", true);
  try {
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];

    const data = {
      id: workerId,
      customer_id: customer.customer_id,
    };

    if (customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.delete("/proxy/workers", {
      data,
    });

    if (response && response.data.success) {
      commit("DELETE_WORKER", workerId);
      dispatch("notifications/pushNotification", "Deleted", { root: true });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    commit("SET_DELETE_WORKER_LOADING", false);
  }
};

export default deleteWorker;
