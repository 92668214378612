<template>
  <div id="app Shifthealth">
    <cws-notifications />
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>

<script>
// Styles
import '@/sass/overrides.sass';

import CwsNotifications from '@/components/cws-notifications/cws-notifications.vue';

export default {
  name: 'App',
  components: {
    CwsNotifications,
  },
  metaInfo: {
    title: 'App',
    titleTemplate: 'console | Shifthealth',
    htmlAttrs: { lang: 'en' },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
    ],
  },
};
</script>
