const fetchDeviceStatus = async ({ commit, dispatch }, { uid }) => {
  commit('SET_SELECTED_DEVICE_STATUS_LOADING', true);
  try {
    const response = await window.axios.get(`/proxy/device-manager/status?device_id=${uid}`);

    if (response && response.data.success) {
      commit('SET_SELECTED_DEVICE_STATUS', response.data.device);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_SELECTED_DEVICE_STATUS_LOADING', false);
  }
};

export default fetchDeviceStatus;
