const setError = ({ commit }, errorMessage) => {
  commit('SET_ERROR', errorMessage);
};

const clearError = ({ commit }) => {
  commit('SET_ERROR', '');
};

const pushNotification = ({ commit }, message) => {
  const id = (Math.random().toString(36) + Date.now().toString(36)).substr(2);

  commit('PUSH_NOTIFICATION', {
    id: id,
    message,
  });
};

const clearNotification = ({ commit }, { id }) => {
  commit('CLEAR_NOTIFICATION', id);
};

const clearAllNotifications = ({ commit }) => {
  commit('CLEAR_ALL_NOTIFICATIONS');
};

export default {
  setError,
  clearError,
  pushNotification,
  clearNotification,
  clearAllNotifications,
};