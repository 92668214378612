const getPatientNotes = async ({ commit, dispatch }) => {
    commit('SET_PATIENT_NOTES', [])
    commit('SET_GET_PATIENT_NOTES_LOADING', true)
    try {
        let url = '/proxy/patient-notes';
        const response = await window.axios.get(url);
        commit('SET_PATIENT_NOTES', response.data)
    } catch (e) {
        dispatch('notifications/setError', e.message, { root: true });
    } finally {
        commit('SET_GET_PATIENT_NOTES_LOADING', false)
    }
}

/*const prepareCodes = (response) => {
    return response.map((item) => {
        const result = {}
        Object.keys(item.generatedCodes).forEach(function(key) {
            result[key] = {}
            item.generatedCodes[key].map((code) => {
                result[key][code] = {
                        status: 'approve',
                        checked: true,
                }
            })
        });
        return {...item, generatedCodes: result};
    })
}*/

export default getPatientNotes