import Cookies from 'js-cookie';

const refreshToken = async ({ commit, dispatch }) => {
  commit('SET_IS_AUTHENTICATING', true);
  try {
    const response = await window.axios.get('/proxy/refresh-token');
    if (response.data && response.data.success === true) {
      Cookies.set('token', response.data.token);
    } else if (response.data && response.data.message) {
      throw { message: response.data.message };
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_IS_AUTHENTICATING', false);
  }
};

export default refreshToken;
