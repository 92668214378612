import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { getAutomationInitialSettings } from "@/util/browserAutomationEvents";

const state = () => ({
  workers: [],
  worker: {
    settings: {
      automation: getAutomationInitialSettings(),
    },
    inputs: [],
  },

  // loaders
  createWorkerLoading: false,
  editWorkerLoading: false,
  deleteWorkerLoading: false,
  fetchWorkerLoading: false,
  fetchWorkersLoading: false,
  runningWorker: false,
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
