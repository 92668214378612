import Vue from 'vue';

const SET_WORKFLOW_HISTORY_LOADING = (state, data) => {
  state.fetchWorkflowHistoryLoading = data;
};

const SET_AUTOMATION_WORKFLOW_HISTORY = (state, { id, workflow }) => {
  const { rows = {}, hasMore } = workflow;
  const data = rows[id] || [];

  Vue.set(state, 'workflowHistory', [
      ...state.workflowHistory,
      ...data
  ])
  Vue.set(state, 'hasMore', hasMore)
};

const CLEAR_WORKFLOW_HISTORY = (state) => {
  state.workflowHistory = [];
  state.hasMore = false;
  state.workflowStatus = {};
};

const SET_AUTOMATION_WORKFLOW_TASKS = (state, opts) => {
  const { storage_id, tasks = [], ioLoading } = opts;

  if (!state.workflowStatus[storage_id]) {
    Vue.set(state.workflowStatus, storage_id, {});
  }

  const payload = {
    loading: ioLoading,
    tasks: tasks,
  };

  Vue.set(state.workflowStatus, storage_id, payload);
};

const SET_HISTORY_CONNECTOR_LIST = (state, { storageId, values }) => {
  Vue.set(state.workflowHistoryConnectors, storageId, values);
};

const SET_WORKFLOW_HISTORY_CONNECTOR_LIST_LOADING = (state, value) => {
  state.fetchWorkflowHistoryConnectorsList = value;
};

const SET_CONNECTOR_DETAILS = (state, data) => {
  Vue.set(state.workflowHistoryConnectorDetails, data.id, data);
}

const SET_CONNECTOR_DETAILS_LOADING = (state, data) => {
  state.fetchconnectorDetailsLoading = data;
};

const SET_RESUME_WORKFLOW_LOADING = (state, payload) => {
  state.resumeWorkflowLoading = payload;
}
const SET_FAILED_TASKS = (state, payload) => {
  state.failedTasks = payload;
}

const PUSH_FAILED_TASKS = (state, payload) => {
  state.failedTasks = [...state.failedTasks, ...payload];
}

const SET_FAILED_TASKS_LOADING = (state, payload) => {
  state.failedTasksLoading = payload;
}
const SET_RETRIED_TASKS = (state, payload) => {
  state.retriedTasks = payload;
}
const SET_RETRIED_TASKS_LOADING = (state, payload) => {
  state.retriedTasksLoading = payload;
}

const SET_WORKFLOW_HISTORY_ITEM = (state, payload) => {
  Vue.set(state, 'workflowHistoryItem', payload)
}

const SET_UPDATE_WORKFLOW_STATS_LOADING = (state, payload) => {
  state.updateWorkflowStatsLoading = payload;
}

export default {
  SET_CONNECTOR_DETAILS,
  CLEAR_WORKFLOW_HISTORY,
  SET_WORKFLOW_HISTORY_LOADING,
  SET_AUTOMATION_WORKFLOW_TASKS,
  SET_AUTOMATION_WORKFLOW_HISTORY,
  SET_HISTORY_CONNECTOR_LIST,
  SET_WORKFLOW_HISTORY_CONNECTOR_LIST_LOADING,
  SET_CONNECTOR_DETAILS_LOADING,
  SET_RESUME_WORKFLOW_LOADING,
  SET_FAILED_TASKS,
  SET_FAILED_TASKS_LOADING,
  SET_RETRIED_TASKS,
  SET_RETRIED_TASKS_LOADING,
  SET_WORKFLOW_HISTORY_ITEM,
  PUSH_FAILED_TASKS,
  SET_UPDATE_WORKFLOW_STATS_LOADING,
};
