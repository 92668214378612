const getWorkflowHistory = async ({ commit, rootGetters, dispatch }, { id, skip = 0, ...otherParams }) => {
  commit('SET_WORKFLOW_HISTORY_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = '/proxy/get-workflow-history';

    let customer_id = customer.customer_id;

    if (skip === 0) {
      commit('CLEAR_WORKFLOW_HISTORY');
    }

    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = {
      customer_id,
      workflow_id: id,
      skip,
      ...otherParams,
    };

    const response = await window.axios.post(url, data);

    if (response && response.data.success) {
      commit('SET_AUTOMATION_WORKFLOW_HISTORY', { id, workflow: response.data.workflows });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_WORKFLOW_HISTORY_LOADING', false);
  }
};

export default getWorkflowHistory;
