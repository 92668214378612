function downloadWorkflow(workflow) {
  const prefix = 'data:text/json;charset=utf-8,';
  const dataStr = prefix + encodeURIComponent(JSON.stringify(workflow));
  var link = document.createElement('a');
  link.setAttribute('href', dataStr);
  link.setAttribute('download', `${workflow.name || workflow.id}.json`);
  link.click();
  link.remove();
}

const exportWorkflow = async (store, workflow_id) => {
  const { rootGetters, dispatch } = store;
  try {
    dispatch(
      'notifications/pushNotification',
      'Exporting workflow. Please wait',
      {
        root: true,
      }
    );
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    let url = `/proxy/export-workflow?workflow_id=${workflow_id}`;
    const response = await window.axios.get(url);

    if (response && response.data.success) {
      downloadWorkflow(response.data.workflow);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  }
};

export default exportWorkflow;
