import { actionsTypes } from './action-types';

export const getAction = (actionType, data_source_name = null) => {
  const types = actionsTypes(data_source_name);

  for (const type of types) {
    const action =
      type.actions &&
      type.actions.find((item) => item.action_type === actionType);

    if (action) {
      const connector = type;
      delete connector.actions;
      return { ...connector, ...action };
    }

    if (type.action_type === actionType) {
      return type;
    }
  }

  return {};
};

const extractConnectors = (connectors) => {
  return connectors.map((connector) => {
    const action = getAction(connector);
    return {
      name: action.name,
      icon: action.icon,
      color: action.color,
      svgIcon: action.svgIcon,
    };
  });
};

export default extractConnectors;
