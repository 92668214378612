const sendBalanceTransactions = async (
  { commit, rootGetters, dispatch },
  paymentData
) => {
  commit('SET_SEND_BALANCE_TRANSACTION_LOADING', true);
  let errorPayload = null;
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    const url = '/proxy/api/payment/send-balance-transaction';

    let customer_id = customer.customer_id;
    if (customer.customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    const data = { ...paymentData, customer_id };
    const response = await window.axios.post(url, data);

    if (!response?.data?.success) {
      throw response.data.error;
    }
  } catch (error) {
    errorPayload = error;
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_SEND_BALANCE_TRANSACTION_LOADING', false);
  }
  return errorPayload;
};

export default sendBalanceTransactions;
