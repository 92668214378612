import {
  sortNodesByHeadAndTail,
  sortActionsTypes,
} from "@/util/workflowDetails";

const setNodes = ({ commit, rootGetters, getters }, { actions = {} }) => {
  let nodes = [];

  if (actions && Object.keys(actions).length > 0) {
    const customer = rootGetters['customer/CUSTOMER'];
    const { data_source_name } = customer.CustomersFeature;

    const startSettings = getters['START_SETTINGS'];
    const trigger_type = startSettings.trigger_type;
    const actionTypes = sortActionsTypes(data_source_name, trigger_type);
    const nodeList = sortNodesByHeadAndTail(actions, actionTypes);
    let idCounter = 1;
    nodes = nodeList.map((node) => {
      if (node.id >= idCounter || node.id === 0) {
        idCounter = node.id + 1;
      }
      //todo: try_catch ? 
      if (node.action_type === "loop") {
        node.settings.cycleBody.forEach((node) => {
          if (node.id >= idCounter || node.id === 0) {
            idCounter = node.id + 1;
          }
        });
      }
      return node;
    });
  }
  commit('SET_NODES', nodes);
  commit('SET_COPY_OF_SAVED_NODES_NODES', nodes);
};

export default setNodes;
