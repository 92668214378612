const googleAction = async ({ commit, dispatch, rootState }, data) => {
  commit("SET_PERFORM_GOOGLE_ACTION", true);
  try {
    let url = "/proxy/google-drive-actions";
    const { customer, selected_customer } = rootState.customer;
    if (customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }
    const response = await window.axios.post(url, data);
    if (!response || !response.data.success) {
      throw response.data;
    }
    return response.data;
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    commit("SET_PERFORM_GOOGLE_ACTION", false);
  }
};

export default googleAction;
