const getFailedTasks = async ({ commit, rootGetters, dispatch }, { storageId, page, push, search, iteration }) => {
  commit('SET_FAILED_TASKS_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = `/proxy/workflows/${storageId}/failed-tasks?page=${page}`;

    let customer_id = customer.customer_id;

    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = {
      customer_id,
    };

    if(search) {
      url += `&search=${search}`;
    }

    if(iteration) {
      url += `&iteration=${iteration}`;
    }

    const response = await window.axios.post(url, data);

    if (response && response.data.success) {
      if(push) {
        commit('PUSH_FAILED_TASKS', response.data.failedTasks);
      } else {
        commit('SET_FAILED_TASKS', response.data.failedTasks);
      }
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_FAILED_TASKS_LOADING', false);
  }
};

export default getFailedTasks;
