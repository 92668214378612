const getters = {
  WORKERS: ({ workers }) => workers,
  WORKER: ({ worker }) => worker,
  CREATE_WORKER_LOADING: ({ createWorkerLoading }) => createWorkerLoading,
  EDIT_WORKER_LOADING: ({ editWorkerLoading }) => editWorkerLoading,
  DELETE_WORKER_LOADING: ({ deleteWorkerLoading }) => deleteWorkerLoading,
  FETCH_WORKER_LOADING: ({ fetchWorkerLoading }) => fetchWorkerLoading,
  FETCH_WORKERS_LOADING: ({ fetchWorkersLoading }) => fetchWorkersLoading,
  RUNNING_WORKER: ({ runningWorker }) => runningWorker,
  WORKER_SETTINGS: ({ worker }) => worker.settings || {},
  WORKER_INPUTS: ({ worker }) => worker.inputs || [],
  WORKER_ACTION: ({ worker }) => worker.action || {},
  WORKER_LOGS: ({ worker }) => worker.logs || [],
};

export default getters;
