const getVideoLinks = async ({ dispatch }, data) => {
  const resp = await window.axios.get(
    `/proxy/workflows/playwright/${data.storageId}/${data.taskId}/video-link`
  );

  if (resp.data.links.length < 1) {
    dispatch('notifications/setError', 'Video(s) not found.', { root: true });
  }

  return resp.data.links;
};

export default getVideoLinks;
