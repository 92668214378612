import createWorker from "./createWorkers";
import deleteWorker from "./deleteWorkers";
import fetchWorkers from "./fetchWorkers";
import fetchWorker from "./fetchWorker";
import updateWorker from "./updateWorker";
import clearWorker from "./clearWorker";

export default {
  createWorker,
  deleteWorker,
  fetchWorkers,
  fetchWorker,
  updateWorker,
  clearWorker,
};
