const updateModel = async ({ commit, rootGetters, dispatch }, payload) => {
    commit("SET_EDIT_MODEL_LOADING", true);
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload.model };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }
        const response = await window.axios.put(`/proxy/models/${payload.id}`, data);
        if (response && response.data.success) {
            dispatch("notifications/pushNotification", "Updated", { root: true });
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    } finally {
        commit("SET_EDIT_MODEL_LOADING", false);
    }
};

export default updateModel;
