import list from './list';
import details from './details';
import history from './history';

export default {
  namespaced: true,
  modules: {
    list,
    details,
    history,
  },
};
