const createEndpoint= async ({ commit, rootGetters, dispatch }, payload) => {
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload.data };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        const response = await window.axios.patch(`/proxy/models/${payload.modelId}/endpoint`, data);
        if (response && response.data.success) {
            dispatch("notifications/pushNotification", "Model deployed", { root: true });
            commit('SET_MODEL', response.data.model)
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    } finally {
        commit("SET_ENDPOINT_CREATION_LOADING", false);
    }
};

export default createEndpoint;
