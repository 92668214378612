const getter = {
    MODELS: ({ models }) => models,
    ENDPOINTS: ({ endpoints }) => endpoints,
    MODEL: ({ model }) => model,
    MODEL_INPUT_FIELDS: ({ model }) => model?.input?.fields || [],
    MODEL_INPUT: ({ model }) => model.input || { fields: [] },
    CREATE_MODEL_LOADING: ({ createModelLoading }) => createModelLoading,
    EDIT_MODEL_LOADING: ({ editModelLoading }) => editModelLoading,
    DELETE_MODEL_LOADING: ({ deleteModelLoading }) => deleteModelLoading,
    FETCH_MODEL_LOADING: ({ fetchModelLoading }) => fetchModelLoading,
    FETCH_MODELS_LOADING: ({ fetchModelsLoading }) => fetchModelsLoading,
    FETCH_ENDPOINTS_LOADING: ({ fetchEndpointsLoading }) => fetchEndpointsLoading,
    RUNNING_MODEL: ({ runningModel }) => runningModel,
    LOCATION_TO_UPLOAD: ({ locationToUpload }) => locationToUpload,
    ENDPOINT_CREATION_LOADING: ({ endpointCreationLoading }) => endpointCreationLoading,
    OUTPUT: ({ output }) => output,
}

export default getter