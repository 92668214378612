const getOneFromWorkflowHistory = async ({ commit, rootGetters, dispatch }, { workflow_id, storage_id }) => {
    commit('SET_WORKFLOW_HISTORY_LOADING', true);
    commit('CLEAR_WORKFLOW_HISTORY');
    try {
        const customer = rootGetters['customer/CUSTOMER'];
        const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

        let url = '/proxy/get-one-from-workflow-history';

        let customer_id = customer.customer_id;

        if (customer_id === 0) {
            customer_id = selected_customer.customer_id;
        }

        const data = {
            customer_id,
            workflow_id,
            storage_id,
        };

        const response = await window.axios.post(url, data);

        if (response && response.data.success) {
            commit('SET_AUTOMATION_WORKFLOW_HISTORY', { id: workflow_id, workflow: response.data.workflows });
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch('notifications/setError', error.message, { root: true });
    } finally {
        commit('SET_WORKFLOW_HISTORY_LOADING', false);
    }
};

export default getOneFromWorkflowHistory;
