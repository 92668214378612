const getScopeRetriedTasks = async ({ dispatch }, { storageId, taskId }) => {
  try {

    let url = `/proxy/workflows/${storageId}/retried-tasks/${taskId}`;

    const response = await window.axios.get(url);

    if (response && response.data.success) {
      return response.data.retriedTasks;
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  }
};

export default getScopeRetriedTasks;
