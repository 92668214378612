import Vue from "vue";

const mutations = {
    SET_MODEL: (state, payload) => {
        state.model = payload;
        state.output = payload.output;
    },
    SET_MODELS: (state, payload) => {
        state.models = payload
    },
    SET_ENDPOINTS: (state, payload) => {
        state.endpoints = payload;
    },
    DELETE_MODEL: (state, id) => {
        state.models = state.models.filter((item) => item.id !== id);
    },
    SET_CREATE_MODEL_LOADING: (state, payload) => {
        state.createModelLoading = payload;
    },
    SET_EDIT_MODEL_LOADING: (state, payload) => {
        state.editModelLoading = payload;
    },
    SET_DELETE_MODEL_LOADING: (state, payload) => {
        state.deleteModelLoading = payload;
    },
    SET_FETCH_MODEL_LOADING: (state, payload) => {
        state.fetchModelLoading = payload;
    },
    SET_FETCH_MODELS_LOADING: (state, payload) => {
        state.fetchModelsLoading = payload;
    },
    SET_MODEL_INPUT: (state, payload) => {
        state.model.input = payload;
    },
    SET_MODEL_INPUT_FIELDS: (state, payload) => {
        if(!state.model.input) {
            state.model.input = { fields: payload }
            return;
        }
        state.model.input.fields = payload;
    },
    SET_FETCH_ENDPOINTS_LOADING: (state, payload) => {
        state.fetchEndpointsLoading = payload;
    },
    SET_RUNNING_MODEL: (state, payload) => {
        state.runningModel = payload;
    },
    SET_MODEL_LOCATION_TO_UPLOAD: (state, payload) => {
        state.locationToUpload = payload;
    },
    SET_OUTPUT: (state, payload) => {
        state.output = payload.output
        if (payload.action) {
            Vue.set(state.model, 'action', payload.action);
        }
        if (payload.taskId) {
            Vue.set(state.model, 'taskId', payload.taskId);
        }
    },
    SET_ENDPOINT_CREATION_LOADING: (state, payload) => {
        state.endpointCreationLoading = payload;
    }
}

export default mutations