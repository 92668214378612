const fetchWorkers = async (
  { commit, rootGetters, dispatch },
  { type } = {}
) => {
  commit('SET_FETCH_WORKERS_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let url = '/proxy/workers';
    let customer_id = customer.customer_id;

    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    url += `?customer_id=${customer_id}`;
    if (type) url += `&type=${type}`;

    const response = await window.axios.get(url);

    if (response && response.data.success) {
      commit('SET_WORKERS', response.data.workers);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_FETCH_WORKERS_LOADING', false);
  }
};

export default fetchWorkers;
