const createPaymentPlan = async (
  { commit, rootGetters, dispatch },
  { data }
) => {
  commit('SET_CREATE_PAYMENT_PLAN_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    let url = '/proxy/api/activate-auto-payment';
    const response = await window.axios.post(url, { ...data, customer_id });

    if (!response || !response.data.success) {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_CREATE_PAYMENT_PLAN_LOADING', false);
  }
};

export default createPaymentPlan;
