function sortByName(a, b) {
  if (a.company_name > b.company_name) return 1;
  if (a.company_name < b.company_name) return -1;
  return 0;
}

const fetchCustomerMappings = async ({ commit, getters, dispatch }) => {
  try {
    const customer = getters["CUSTOMER"];

    const response = await window.axios.post("/proxy/get-customer-mappings", {
      customer_id: customer.customer_id,
    });

    if (response && response.data.success) {
      commit("SET_CUSTOMER_MAPPINGS", response.data.mappings.sort(sortByName));
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    // somethng here
  }
};

export default fetchCustomerMappings;
