const fetchPromt = async ({ commit, rootGetters, dispatch }, promtId) => {
  commit("SET_FETCH_PROMT_LOADING", true);
  try {
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
    let url = `/proxy/promts/${promtId}`;
    let customer_id = customer.customer_id;

    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    url += `?customer_id=${customer_id}`;

    const response = await window.axios.get(url);

    if (response && response.data.success) {
      commit("SET_PROMT", response.data.promt);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    commit("SET_FETCH_PROMT_LOADING", false);
  }
};

export default fetchPromt;
