const getters = {
  PATIENT: ({ patient }) => patient,
  MAIN_CARD: ({ patientCard }) => patientCard,
  AUTOPAYMENT: ({ patient }) => {
    return patient ? patient.autoPayment : null;
  },
  PATIENTS_LIST: ({ patientsList }) => patientsList,
  GET_PATIENT_MESSAGES: ({ messages }) => messages || {},
};

export default getters;
