export const fetchWebhooks = async ({ commit, dispatch, rootGetters }) => {
  commit('SET_WEBHOOKS_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let customer_id = customer?.customer_id ?? customer?.user?.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.get('/proxy/webhooks' + `?customer_id=${customer_id}`);
    if (response && response.data.success) {
      commit('SET_WEBHOOKS', response.data.webhooks);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_WEBHOOKS_LOADING', false);
  }
};



