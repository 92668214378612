const getTasks = async (
  { commit, rootGetters, dispatch },
  { storage_id }
) => {
  try {
    commit("SET_AUTOMATION_WORKFLOW_TASKS", {
      storage_id,
      ioLoading: true,
    });
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];

    let url = "/proxy/get-workflow-status";

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = { customer_id, storage_id, extended: true };

    const response = await window.axios.post(url, data);

    if (response && response.data.success) {
      commit("SET_AUTOMATION_WORKFLOW_TASKS", {
        storage_id,
        tasks: response.data.workflow,
        ioLoading: false,
      });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  }
};

export default getTasks;
