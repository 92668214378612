import cloneDeep from 'lodash/cloneDeep';
import uuidv4 from './uuid_generator';

export const automationEvents = {
  open: 'open',
  click: 'click',
  type: 'type',
};

export const getEmptyEvent = () => {
  const event = {
    id: uuidv4(),
    command: 'Empty',
    name: '',
    descr: '',
    target: '',
    targets: [],
  };
  return cloneDeep(event);
};

const getOpenEvent = () => {
  const event = {
    id: uuidv4(),
    command: 'open',
    name: '',
    descr: '',
    target: '/',
    targets: [],
  };
  return cloneDeep(event);
};

// Identical to Selenium IDE parameters
export const getAutomationInitialSettings = () => {
  const testId = uuidv4();
  const settings = {
    id: uuidv4(),
    version: '2.0',
    name: 'SH automation',
    url: '',
    tests: [
      {
        id: testId,
        name: 'SH test',
        commands: [getOpenEvent()],
      },
    ],
    suites: [
      {
        id: uuidv4(),
        name: 'Default Suite',
        persistSession: false,
        parallel: false,
        timeout: 300,
        tests: [testId],
      },
    ],
    urls: [],
    plugins: [],
  };
  return cloneDeep(settings);
};

export const availableEvents = [
  {
    command: automationEvents.open,
    name: 'Open subpath',
    descr: 'Direct a chrome to a web address during RPA session.',
    target: '/',
    targets: [],
  },
  {
    command: automationEvents.click,
    name: 'Click',
    descr:
      'Get an element by the provided query selector, Click on an element.',
    target: '',
    value: '',
    targets: [],
  },
  {
    command: automationEvents.type,
    name: 'Enter data into field',
    descr:
      'This action simulates entering characters into a form, textbox or another element on the page.',
    target: '',
    value: '',
    targets: [],
  },
];

export const customEvents = [
  'screenshot',
  'upload',
  'downloadFromLink',
  'awaitDownload',
  'refreshPage',
  'getOTPToken',
];

export const seleniumEvents = [
  'open',
  'click',
  'clickAt',
  'check',
  'uncheck',
  'debugger',
  'doubleClick',
  'doubleClickAt',
  'dragAndDropToObject',
  'type',
  'sendKeys',
  'echo',
  'run',
  'runScript',
  'executeScript',
  'executeAsyncScript',
  'pause',
  'verifyChecked',
  'verifyNotChecked',
  'verifyEditable',
  'verifyNotEditable',
  'verifyElementPresent',
  'verifyElementNotPresent',
  'verifySelectedValue',
  'verifyNotSelectedValue',
  'verifyValue',
  'verifyText',
  'verifyTitle',
  'verifyNotText',
  'verifySelectedLabel',
  'assertChecked',
  'assertNotChecked',
  'assertEditable',
  'assertNotEditable',
  'assertElementPresent',
  'assertElementNotPresent',
  'assertSelectedValue',
  'assertNotSelectedValue',
  'assertValue',
  'assertText',
  'assertTitle',
  'assertSelectedLabel',
  'store',
  'storeText',
  'storeJson',
  'storeValue',
  'storeTitle',
  'storeWindowHandle',
  'storeXpathCount',
  'storeAttribute',
  'select',
  'addSelection',
  'removeSelection',
  'selectFrame',
  'selectWindow',
  'close',
  'mouseDown',
  'mouseDownAt',
  'mouseUp',
  'mouseUpAt',
  'mouseMove',
  'mouseMoveAt',
  'mouseOver',
  'mouseOut',
  'assertAlert',
  'assertNotText',
  'assertPrompt',
  'assertConfirmation',
  'webdriverAnswerOnVisiblePrompt',
  'webdriverChooseOkOnVisibleConfirmation',
  'webdriverChooseCancelOnVisibleConfirmation',
  'webdriverChooseCancelOnVisiblePrompt',
  'editContent',
  'submit',
  'answerOnNextPrompt',
  'chooseCancelOnNextConfirmation',
  'chooseCancelOnNextPrompt',
  'chooseOkOnNextConfirmation',
  'setSpeed',
  'setWindowSize',
  'do',
  'else',
  'elseIf',
  'end',
  'forEach',
  'if',
  'repeatIf',
  'times',
  'while',
  'assert',
  'verify',
  'waitForElementPresent',
  'waitForElementNotPresent',
  'waitForElementVisible',
  'waitForElementNotVisible',
  'waitForElementEditable',
  'waitForElementNotEditable',
  'waitForText',
  ...customEvents,
];
