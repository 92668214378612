import createModel from "./createModel";
import deleteModel from './deleteModel';
import fetchModel from './fetchModel';
import fetchModels from './fetchModels';
import updateModel from './updateModel';
import fetchEndpoints from './fetchEndpoints';
import uploadModelArtifacts from './uploadModelArtifacts';
import clearModel from './clearModel';
import createEndpoint from './createEndpoint';
import buildDockerImage from './buildDockerImage';

export default {
    createModel,
    deleteModel,
    fetchModel,
    fetchModels,
    updateModel,
    fetchEndpoints,
    uploadModelArtifacts,
    clearModel,
    createEndpoint,
    buildDockerImage,
}