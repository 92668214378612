const getInvoiceActivity = async (
  { commit, rootGetters, dispatch },
  options
) => {
  try {
    commit('SET_INVOICE_ACTIVITIES', []);
    commit('SET_FETCH_ACTIVITIES_LOADING', true);
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    const { invoice_id, pid } = options;

    const data = { customer_id, pid, invoice_id };
    const response = await window.axios.post(
      '/proxy/api/payment/get-invoice-activities',
      data
    );

    if (response && response.data.success) {
      commit('SET_INVOICE_ACTIVITIES', response.data.activities);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_FETCH_ACTIVITIES_LOADING', false);
  }
};

export default getInvoiceActivity;
