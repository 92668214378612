export default {
    SET_PATIENT_NOTES: (state, payload) => {
        state.patientNotes = payload;
    },
    SET_GET_PATIENT_NOTES_LOADING: (state, payload) => {
        state.getPatientNotesLoading = payload;
    },
    SET_APPROVE_NOTE_LOADING: (state, payload) => {
        state.approveNoteLoading = payload;
    },
}