export default {
  APP_TYPES: (state) => {
    return state.appTypes;
  },
  CONTENT_PRELOADER: (state) => {
    return state.contentPreloader;
  },
  SHOW_DRAWER: (state) => {
    return state.showDrawer;
  },
  SHOW_HEADER: (state) => {
    return state.showHeader;
  },
  SERVICE_TYPES: (state) => {
    return state.service_types;
  },
  SHOW_BOT_SCREEN: (state) => {
    return state.showAddBotScreen;
  },
  NOTIFICATIONS: (state) => {
    return state.notifications;
  },
};