import cloneDeep from 'lodash/cloneDeep';

const mutations = {
  SET_PATIENT: (state, payload) => {
    state.patient = payload;
  },
  SET_AUTOPAYMENT: (state, payload) => {
    const patient = cloneDeep(state.patient);
    patient.autoPayment = payload || {};
    state.patient = patient;
  },
  SET_AUTOPAY: (state, payload) => {
    const patient = cloneDeep(state.patient);
    patient.autopay = payload;
    state.patient = patient;
  },
  SET_MAIN_CARD: (state, payload) => {
    let patientCard = cloneDeep(state.patientCard);
    if (payload && payload.exp_month && payload.exp_year) {
      payload.date =
        ('0' + payload.exp_month).slice(-2) + '/' + payload.exp_year;
    }
    patientCard = payload;
    state.patientCard = patientCard;

    const cards = cloneDeep(state.patient.cards);
    cards.forEach((card) => {
      if (card.id == payload.id) {
        card.selected = true;
        card.is_primary = true;
      } else {
        card.selected = false;
        card.is_primary = false;
      }
    });
    state.patient.cards = cards;
  },
  SET_PATIENT_CARDS: (state, payload) => {
    const patient = cloneDeep(state.patient);
    patient.cards = [];
    payload.forEach((card) => {
      if (card.exp_month && card.exp_year) {
        card.date = ('0' + card.exp_month).slice(-2) + '/' + card.exp_year;
      }
      patient.cards.push(card);
    });
    state.patient = patient;
  },
  ADD_CARD: (state, payload) => {
    const patient = cloneDeep(state.patient);
    patient.cards.push(payload);
    state.patient = patient;
  },
  SELECT_CARD: (state, payload) => {
    const patient = cloneDeep(state.patient);
    patient.cards.forEach((card) => {
      card.selected = card.id === payload.id;
    });
  },
  DELETE_CARD: (state, payload) => {
    const patient = cloneDeep(state.patient);
    patient.cards.forEach((card, index) => {
      if (card.id === payload.id) patient.cards.splice(index, 1);
    });
    state.patient = patient;
  },
  ADD_PATIENT_BALANCE: (state, payload) => {
    const patient = cloneDeep(state.patient);
    patient.balance = payload;
    state.patient = patient;
  },
  ADD_PAYMENT_HISTORY: (state, payload) => {
    const patient = cloneDeep(state.patient);
    patient.patientHistory = payload;
    state.patient = patient;
  },
  SET_PATIENTS: (state, payload) => {
    state.patientsList = payload;
  },
  SET_OB_STATUS: (state, payload) => {
    const patient = cloneDeep(state.patient);
    patient.ob_status = payload;
    state.patient = patient;
  },
  SET_PATIENT_MESSAGES: (state, payload) => {
    const { patient_id, messages } = payload;
    state.messages = {
      ...state.messages,
      [patient_id]: messages,
    };
  },
};

export default mutations;
