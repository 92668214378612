const resumeWorkflow = async ({ rootGetters, dispatch, commit }, storageId) => {
  try {
    commit('SET_RESUME_WORKFLOW_LOADING', true);

    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    const data = { customer_id: customer.customer_id, storageId };
    if (customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post('/proxy/resume-workflow', data);
    if (response && response.data.success) {
      dispatch('notifications/pushNotification', 'Workflow Resumed', {
        root: true,
      });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_RESUME_WORKFLOW_LOADING', false);
  }
};
export default resumeWorkflow;
