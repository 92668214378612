const retryActions = async ({ commit, rootGetters, dispatch }, data) => {
  commit("SET_EXECUTE_SINGLE_ACTION_LOADING", true);
  try {
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];

    let url = `/proxy/workflows/${data.storage_id}/tasks/${data.task_id}/retry?mode=${data.mode}`;
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    data.customer_id = customer_id;
    const response = await window.axios.post(url, data, { timeout: 300000 });

    if (!response || !response.data || !response.data.success) {
      throw response.data;
    }
    return response.data;
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
    return error;
  } finally {
    // stuff here
    commit("SET_EXECUTE_SINGLE_ACTION_LOADING", false);
  }
};

export default retryActions;
