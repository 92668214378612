const mutations = {
  SET_FETCH_AUTOMATION_WORKFLOWS_LOADING: (state, data) => {
    state.fetchAutomationWorkflowLoading = data;
  },
  SET_AUTOMATION_WORKFLOWS: (state, data) => {
    state.automationWorkflows = data;
  },
  SET_AUTOMATION_WORKFLOWS_COUNT: (state, data) => {
    state.workflowsCount = data;
  },
  SET_CHANGE_WORKFLOW_STATUS_LOADING: (state, { id, loading }) => {
    state.editWorkflowLoading = { ...state.editWorkflowLoading, [id]: loading };
  },
  SET_WORKFLOW_OWNERS_LOADING: (state, { id, loading }) => {
    state.setWorkflowOwnerLoading = {
      ...state.setWorkflowOwnerLoading,
      [id]: loading,
    };
  },
  UPDATE_AUTOMATION_WORKFLOW: (state, { id, workflow }) => {
    state.automationWorkflows = state.automationWorkflows.map((item) => {
      if (item.id === id) {
        return workflow;
      }

      return item;
    });
  },
  SET_DELETE_WORKFLOW_LOADING: (state, data) => {
    state.deleteWorkflowLoading = data;
  },
  CLEAR_WORKFLOW_HISTORY: (state, id) => {
    state.automationWorkflowHistory[id] = {};
  },
  SET_SELECTED_AUTOMATION_WORKFLOW: (state, data) => {
    state.selectedAutomationWorkflow = data;
  },
  SET_FETCH_AUTOMATION_WORKFLOW_LOADING: (state, data) => {
    state.selectedAutomationWorkflowLoading = data;
  },
  UPDATE_AUTOMATION_WORKFLOW_LOADING: (state, data) => {
    state.updateAutomationWorkflowLoading = data;
  },
  SET_PERFORM_GOOGLE_ACTION: (state, data) => {
    state.performingGoogleAction = data;
  },
  SET_PERFORM_OUTLOOk_ACTION: (state, data) => {
    state.performingOutlookAction = data;
  },
  SET_PERFORM_TWILIO_ACTION: (state, data) => {
    state.performingTwilioAction = data;
  },
  SET_PERFORM_SFTP_ACTION: (state, data) => {
    state.performingSFTPAction = data;
  },
  SET_AUTOMATION_WORKFLOW_STATS: (state, opts) => {
    const { id, storage_id, stats, loading } = opts;
    const currentHistory = state.automationWorkflowHistory[id];
    const itemIndex = currentHistory.history.findIndex(
      (item) => item.id === storage_id
    );
    const item = { ...currentHistory.history[itemIndex] };

    if (stats) {
      item.stats = stats;
      item.stats.loading = loading;
    } else if (!item.stats) {
      item.stats = { loading };
    }
    currentHistory.history[itemIndex] = item;

    state.automationWorkflowHistory = {
      ...state.automationWorkflowHistory,
      [id]: { ...currentHistory },
    };
  },
  SET_AUTOMATION_WORKFLOW_TASKS: (state, opts) => {
    const { id, storage_id, tasks, ioLoading } = opts;
    const currentHistory = state.automationWorkflowHistory[id];
    const itemIndex = currentHistory.history.findIndex(
      (item) => item.id === storage_id
    );
    let item = { ...currentHistory.history[itemIndex] };

    if (tasks) {
      item = { ...item, ...tasks, ioLoading };
    } else {
      item.ioLoading = ioLoading;
    }

    currentHistory.history[itemIndex] = item;
    state.automationWorkflowHistory = {
      ...state.automationWorkflowHistory,
      [id]: { ...currentHistory },
    };
  },
  SET_FETCH_AUTOMATION_WORKFLOWS_NAMES_LOADING: (state, data) => {
    state.fetchAutomationWorkflowNamesLoading = data;
  },
  SET_AUTOMATION_WORKFLOWS_NAMES: (state, data) => {
    state.automationWorkflowsNames = data;
  }
};

export default mutations;
