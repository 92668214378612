import { getWorkflowStartSettings } from '@/util/workflowDetails';

const workflowSetup = ({ commit, rootGetters, dispatch }) => {
  const workflow = rootGetters['workflows/details/WORKFLOW'];
  if (!workflow || !Object.keys(workflow).length) return;

  const startSettings = getWorkflowStartSettings(workflow);
  commit('SET_CREDENTIALS', workflow.credentials ?? []);
  commit('SET_START_SETTINGS', {
    ...startSettings,
    s3StorageEnabled: workflow.IOStorageType === 's3',
  });
  dispatch('setNodes', workflow);
};

export default workflowSetup;
