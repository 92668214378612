const toggleBatchStatus = async (
  { commit, rootGetters, dispatch },
  batchId 
) => {
  commit('SET_TOGGLE_BATCH_STATUS_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let url = '/proxy/api/payment/payment-plans/toggle';
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.put(url, {
      autopay_batch_id: batchId,
      customer_id,
    });

    if (!response || !response.data.success) {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_TOGGLE_BATCH_STATUS_LOADING', false);
  }
};

export default toggleBatchStatus;
