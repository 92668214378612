export default {
  SET_APP_TYPES: (state, payload) => {
    state.appTypes = payload;
  },
  SET_SHOW_DRAWER: (state, payload) => {
    state.showDrawer = payload;
  },
  SET_SHOW_HEADER: (state, payload) => {
    state.showHeader = payload;
  },
  SET_CONTENT_PRELOADER: (state, payload) => {
    state.contentPreloader = payload;
  },
  SET_SHOW_BOT_SCREEN: (state, payload) => {
    state.showAddBotScreen = payload;
  },
  SET_SERVICE_TYPES: (state, payload) => {
    state.service_types = payload;
  },
  ADD_NOTIFICATION: (state, payload) => {
    state.notifications.unshift(payload);
  },
};
