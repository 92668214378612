import { addUIFields, actionsTypes } from './action-types';

export const sortActionsTypes = (name, trigger_type) => {
  const at = actionsTypes(name);

  return [...at].filter((action) => {
    return !(
      action.action_type === 'human_in_the_loop' &&
      trigger_type &&
      trigger_type !== 'manual'
    );
  });
};

export const sortNodesByHeadAndTail = (actions, types) => {
  if (!actions || !Object.values(actions).length) return;

  let payloadsList = [];

  const actionsList = Object.values(actions).map((v) => v);
  const headAction = actionsList.find(
    (action) => action.settings.head === null
  );
  payloadsList.push(headAction);

  let tail = headAction.settings.tail;
  while (tail !== null) {
    const nextNode = actionsList.find((action) => action.id == tail);
    if (!nextNode) {
      throw new Error(`Tail node ${tail} not found in workflow`);
    }
    payloadsList.push(nextNode);
    tail = nextNode.settings.tail;
  }

  return addUIFields(payloadsList, types);
};

export const getWorkflowStartSettings = (workflow = {}) => {
  if (workflow.trigger_type) {
    const { trigger_type, trigger } = workflow;

    switch (trigger_type) {
      case 'cron': {
        const split = trigger.split('|');
        const cron = split[0];
        const splitCron = cron.split(' ');
        const settings = { trigger_type };

        try {
          settings.time_at = `${splitCron[2]}:${splitCron[1]}`;
          settings.day = splitCron[3] === '*' ? null : +splitCron[3];
          settings.week = splitCron[5] === '*' ? [] : splitCron[5].split(',');

          if (settings.day && settings.day !== '*') {
            settings.period = 'month';
          } else if (settings.week.length > 1) {
            settings.period = 'day';
          } else {
            settings.period = 'week';
          }
        } catch {
          settings.custom = cron;
          settings.period = 'custom';
        }

        return settings;
      }
      case 'event':
        return {
          trigger_type,
          trigger_subtype: trigger,
        };
      default:
        return {
          trigger_type,
        };
    }
  } else {
    return {
      period: null,
      time_at: null,
      day: null,
      week: [],
      trigger_subtype: null,
      trigger_type: 'cron',
    };
  }
};

export const getExternalWorkflowActivatorCreds = (
  workflowCredentialsIds,
  credentials
) => {
  return credentials.filter(
    (credential) =>
      workflowCredentialsIds.includes(credential.id) &&
      credential.credential_type === 'externalWorkflowActivator'
  );
};
