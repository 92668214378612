const addCustomerUser = async ({ commit, getters, rootGetters,  dispatch }, { email, password, is_privileged }) => {
  commit('SET_ADD_CUSTOMER_USER_LOADING', true);

  try {
    const customer = getters['CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    const data = {
      email,
      password,
      is_privileged,
    };

    if (customer && customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post("/proxy/create-user", data);

    if (response.data.success) {
      dispatch('getCustomerUsers');
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    // somethng here
    commit('SET_ADD_CUSTOMER_USER_LOADING', false);
  }
};

export default addCustomerUser;
