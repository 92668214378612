const deleteWorkflow = async ({ commit, dispatch }, { id }) => {
  commit('SET_DELETE_WORKFLOW_LOADING', true);
  try {
    let url = '/proxy/delete-workflow';

    const response = await window.axios.post(url, { workflow_id: id });

    if (response && response.data.success) {
      dispatch('fetchWorkflows');
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_DELETE_WORKFLOW_LOADING', false);
  }
};

export default deleteWorkflow;
