import cloneDeep from "lodash/cloneDeep";

const updateCustomerMappings = async (
  { commit, state, getters, dispatch },
  data
) => {
  try {
    const customerMappings = getters["CUSTOMER_MAPPINGS"];

    const response = await window.axios.put("/proxy/edit-greenway-alias", data);

    if (response && response.data.success) {
      const mapping = customerMappings[data.mapIndex];

      const { aliasIndex, alias, mapIndex } = data;

      mapping.greenway_aliases.splice(aliasIndex, 1, alias);
      state.customerMappings[mapIndex] = mapping;

      commit("SET_CUSTOMER_MAPPINGS", cloneDeep(state.customerMappings));
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  }
};

export default updateCustomerMappings;
