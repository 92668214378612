import cloneDeep from 'lodash/cloneDeep';
import { recursiveAddNode, findNode } from '@/util/action-types';
import uuidv4 from '@/util/uuid_generator';
import { allowedNestedActionTypes } from '@/util/action-types'

const setStartSettings = ({ commit }, settings) => {
  commit('SET_START_SETTINGS', settings);
  commit('WORKFLOW_EDITED', true);
};

const insertNode = ({ commit, getters }, { node, options }) => {
  const nodes = getters['NODES'];
  const { index, parentNode, branch, parentNodeList } = options;
  const newNode = cloneDeep(node);

  if (!newNode.id) {
    newNode.id = uuidv4();
  }

  newNode.path = {};
  newNode.path.parentNode = parentNode;
  newNode.path.branch = branch;
  let nodeList = cloneDeep(nodes);

  if (parentNode) {
    const foundParentNode = findNode(nodeList, parentNode.id);
    const parentType = foundParentNode.action_type;
    if (parentType === 'conditional') {
      nodeList = cloneDeep(foundParentNode.settings[branch]);
    } else if (parentType === 'loop') {
      nodeList = cloneDeep(foundParentNode.settings.cycleBody);
    } else if (parentType === 'try_catch') {
      nodeList = cloneDeep(foundParentNode.settings.tryCatchBody);
    }
    else if (allowedNestedActionTypes.includes(foundParentNode.action_type)) {
      nodeList = cloneDeep(foundParentNode.settings.nestedActions || []);
    }
  }

  // link
  let insertIndex = index;
  if (insertIndex > nodeList.length) {
    insertIndex = nodeList.length;
  }
  nodeList.splice(insertIndex, 0, newNode);
  newNode.settings.head = null;
  newNode.settings.tail = null;

  if (nodeList[insertIndex - 1]) {
    nodeList[insertIndex].settings.head = nodeList[insertIndex - 1].id;
    nodeList[insertIndex - 1].settings.tail = nodeList[insertIndex].id;
  }

  if (nodeList[insertIndex + 1]) {
    nodeList[insertIndex].settings.tail = nodeList[insertIndex + 1].id;
    nodeList[insertIndex + 1].settings.head = nodeList[insertIndex].id;
  }

  if (parentNode) {
    commit(
      'SET_NODES',
      recursiveAddNode(cloneDeep(nodes), parentNodeList, nodeList)
    );
  } else {
    commit('SET_NODES', nodeList);
  }
  commit('WORKFLOW_EDITED');
  commit('SET_SELECTED_NODE', newNode);
  commit('SET_SELECTED_NODE_OPTIONS', { index, parentNode, parentNodeList });
};

const emptyInput = {
  inputNodeId: null,
  inputFieldId: null,
  inputFieldName: null,
  inputFieldPath: null,
};

function resetNodesInput(node) {
  if (node.input) {
    node.input = emptyInput;
  } else if (node.inputs) {
    // for object helper. Though it makes no sense to have it first
    node.inputs = [emptyInput];
  }
  return node;
}

function removeFromLinkedList(nodes, index) {
  if (nodes[index - 1]) {
    if (nodes[index + 1]) {
      nodes[index - 1].settings.tail = nodes[index + 1].id;
    } else {
      nodes[index - 1].settings.tail = null;
    }
    if (index - 1 === 0) {
      nodes[index - 1].settings.head = null;
      nodes[index - 1] = resetNodesInput(nodes[index - 1]);
    }
  }

  if (nodes[index + 1]) {
    if (nodes[index - 1]) {
      nodes[index + 1].settings.head = nodes[index - 1].id;
    } else {
      nodes[index + 1].settings.head = null;
      nodes[index + 1] = resetNodesInput(nodes[index + 1]);
    }
    if (index + 1 === 0 && index + 1 === nodes.length - 1) {
      nodes[index + 1].settings.tail = null;
    }
  }
  nodes.splice(index, 1);

  return nodes;
}

const removeNode = ({ commit, getters }, node) => {
  const nodes = getters['NODES'];
  const selectedNodeOption = getters['SELECTED_NODE_OPTIONS'];
  const { index, parentNode, parentNodeList } = selectedNodeOption;
  let nodeList = cloneDeep(nodes);

  const { branch } = node.path;

  if (parentNode) {
    const parentType = parentNode.action_type;
    if (parentType === 'conditional') {
      nodeList = cloneDeep(parentNode.settings[branch]);
    } else if (parentType === 'loop') {
      nodeList = cloneDeep(parentNode.settings.cycleBody);
    }
    else if (parentType === 'try_catch') {
      nodeList = cloneDeep(parentNode.settings.tryCatchBody);
    }
    else if (allowedNestedActionTypes.includes(parentNode.action_type)) {
      nodeList = cloneDeep(parentNode.settings.nestedActions || []);
    }
  }

  const newNodeList = removeFromLinkedList(nodeList, index);

  if (parentNode) {
    commit(
      'SET_NODES',
      recursiveAddNode(cloneDeep(nodes), parentNodeList, newNodeList)
    );
  } else {
    commit('SET_NODES', newNodeList);
  }
  commit('WORKFLOW_EDITED');
  commit('SET_SELECTED_NODE', null);
  commit('SET_SELECTED_NODE_OPTIONS', null);
};

const editWorkflowProperty = ({ commit }, { key, value }) => {
  commit('EDIT_WORKFLOW_PROPERTY', { key, value });
};

export default {
  setStartSettings,
  insertNode,
  removeNode,
  editWorkflowProperty,
};
