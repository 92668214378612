const deleteAuthentication = async ({ commit, rootGetters, dispatch }, { id }) => {
  commit('SET_DELETE_CREDENTIALS_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    const data = {
      id: id,
      customer_id: customer.customer_id,
    };

    if (customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.delete('/proxy/credentials-manager', {
      data,
    });

    if (response && response.data.success && !response.data.workflows) {
      commit('DELETE_AUTHENTICATION', id);
    } else if (response.data.workflows) {
      commit('SET_WORKFLOWS_USING_CREDENTIALS', response.data.workflows);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    // stuff here
    commit('SET_DELETE_CREDENTIALS_LOADING', false);
  }
};

export default deleteAuthentication;
