const updateDevice = async ({ dispatch, commit }, { uid, settings }) => {
  commit('ADD_UPDATE_DEVICE_LOADING', true);

  try {
    const response = await window.axios.patch('/proxy/device-manager', {
      device_id: uid,
      settings,
    });
    if (response && response.data.success) {
      dispatch('clearSelectedDevice');
      dispatch('fetchDevices');
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('ADD_UPDATE_DEVICE_LOADING', false);
  }
};

export default updateDevice;
