const getters = {
  AUTHENTICATIONS: ({ authentications }) => authentications,
  CREATE_CREDENTIALS_LOADING: ({ createCredentialsLoading }) => createCredentialsLoading,
  EDIT_CREDENTIALS_LOADING: ({ editCredentialsLoading }) => editCredentialsLoading,
  DELETE_CREDENTIALS_LOADING: ({ deleteCredentialsLoading }) => deleteCredentialsLoading,
  FETCH_CREDENTIALS_LOADING: ({ fetchCredentialsLoading }) => fetchCredentialsLoading,
  WORKFLOWS_USING_CREDENTIAL_TO_BE_DELETED:({ workflowsUsingCredentialToDelete }) => workflowsUsingCredentialToDelete,
};

export default getters;
