const confirmPaymentPlan = async (
  { commit, rootGetters, dispatch },
  options
) => {
  try {
    commit('SET_SAVE_PAYMENT_PLAN_LOADING', true);
    let url = '/proxy/api/activate-auto-payment';
    if (options.deactivate) {
      url = '/proxy/api/deactivate-auto-payment';
    }

    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = { customer_id, ...options };
    const response = await window.axios.post(url, data);

    if (response && response.data.success) {
      commit('SET_AUTOPAYMENT', response.data.output);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_SAVE_PAYMENT_PLAN_LOADING', false);
  }
};

export default confirmPaymentPlan;
