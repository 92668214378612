import createTool from "./createTools";
import deleteTool from "./deleteTools";
import fetchTools from "./fetchTools";
import fetchTool from "./fetchTool";
import updateTool from "./updateTool";

export default {
    createTool,
    deleteTool,
    fetchTools,
    fetchTool,
    updateTool,
};
