const setTaskInput = async ({ commit, dispatch }, data) => {
  try {
    commit("SET_SET_INPUT_TASK_LOADING", true);

    const response = await window.axios.post("/proxy/set-task-input", data);
    if (!response || !response.data.success) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    commit("SET_INPUT_TASK_QUESTIONS", null);
    commit("SET_SET_INPUT_TASK_LOADING", false);
  }
};

export default setTaskInput;
