export default {
  DEVICES: (state) => {
    return state.devices;
  },
  SELECTED_DEVICE: (state) => {
    return state.selectedDevice;
  },
  SELECTED_DEVICE_STATUS: (state) => {
    return state.selectedDeviceStatus;
  },
  SELECTED_DEVICE_HAS_STATUS: (state) => {
    return Boolean(Object.keys(state.selectedDeviceStatus).length);
  },
  DEVICES_LOADING: (state) => {
    return state.devicesLoading;
  },
  SELECTED_DEVICE_LOADING: (state) => {
    return state.selectedDeviceLoading;
  },
  SELECTED_DEVICE_STATUS_LOADING: (state) => {
    return state.selectedDeviceStatusLoading;
  },
  ADD_UPDATE_DEVICE_LOADING: (state) => {
    return state.addUpdateDeviceLoading;
  },
  REMOVE_DEVICE_LOADING: (state) => {
    return state.removeDeviceLoading;
  }
};
