const fetchAuthentications = async ({ commit, rootGetters, dispatch }) => {
  commit('SET_FETCH_CREDENTIALS_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = '/proxy/credentials-manager';

    let customer_id = customer?.customer_id ?? customer?.user?.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    url += `?customer_id=${customer_id}`;

    const response = await window.axios.get(url);

    if (response && response.data.success) {
      commit('SET_AUTHENTICATIONS', response.data.credentials);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    // stuff here
    commit('SET_FETCH_CREDENTIALS_LOADING', false);
  }
};

export default fetchAuthentications;
