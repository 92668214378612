const createSystemPrompt = async ({ commit, rootGetters, dispatch }, payload) => {
    commit("SET_UPDATE_SYSTEM_PROMPT_LOADING", true);
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload, customer_id: customer.customer_id };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        const response = await window.axios.post(`/proxy/ai/system-prompts`, data);
        if (response && response.data.success) {
            commit('SET_SYSTEM_PROMPT', response.data.systemPrompt)
            return response.data.systemPrompt;
        }
        else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    } finally {
        commit("SET_UPDATE_SYSTEM_PROMPT_LOADING", false);
    }
};

export default createSystemPrompt;