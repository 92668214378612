const fetchAuthentications = async ({ commit, rootGetters, dispatch }, authentication) => {
  commit('SET_EDIT_CREDENTIALS_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    const data = {
      ...authentication,
    };

    if (customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.patch('/proxy/credentials-manager', data);

    if (response && response.data.success) {
      commit('UPDATE_AUTHENTICATION', { id: authentication.id, credentials: response.data.created_group });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    // stuff here
    commit('SET_EDIT_CREDENTIALS_LOADING', false);
  }
};

export default fetchAuthentications;
