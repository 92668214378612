const setOptOut = async ({ rootGetters, dispatch }, { opt_out, pid }) => {
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = {
      customer_id,
      pid,
      value: opt_out,
    };

    const response = await window.axios.post('/proxy/set-opt-out', data);

    if (response && response.data.success) {
      dispatch('patient/getPatient', { patientId: pid }, { root: true });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  }
};

export default setOptOut;
