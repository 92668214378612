import cloneDeep from "lodash/cloneDeep";
import { allowedNestedActionTypes } from '@/util/action-types'
import { isArray } from "lodash";

const updateNodes = ({ commit, getters }, updatedNode) => {
  const find = (nodeList) => {
    return nodeList.map((node) => {
      if (node.id === updatedNode.id) {
        return updatedNode;
      } else if (node.action_type === "loop") {
        return {
          ...node,
          settings: {
            ...node.settings,
            cycleBody: find([...node.settings.cycleBody]),
          },
        };
      } else if (node.action_type === "try_catch") {
        return {
          ...node,
          settings: {
            ...node.settings,
            tryCatchBody: find([...node.settings.tryCatchBody]),
          },
        };
      }
      else if (node.action_type === "conditional") {
        return {
          ...node,
          settings: {
            ...node.settings,
            true: find([...node.settings.true]),
            false: find([...node.settings.false]),
          },
        };
      } else if (allowedNestedActionTypes.includes(node.action_type)) {
        if (!node.settings.nestedActions || !isArray(node.settings.nestedActions)) return node;
        return {
          ...node,
          settings: {
            ...node.settings,
            nestedActions: find([...node.settings.nestedActions]),
          }
        }
      }
      else {
        return node;
      }
    });
  };
  const nodes = find(cloneDeep(getters["NODES"]));

  commit("SET_NODES", nodes);
  commit("WORKFLOW_EDITED");
};

export default updateNodes;
