import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  customer: null,
  user: null,
  customer_users: [],
  customers: [{ customer_id: 0, CustomerName: 'Internal admins' }],
  selected_customer: { customer_id: 0, CustomerName: 'Internal admins' },
  customerMappings: [],
  featureFlags: {},

  customerUsersLoading: false,
  addCustomerUserLoading: false,
  deleteCustomerUsersLoading: false,
  customerDataLoading: false,
  getLoggedInUserLoading: false,
  getFeatureFlagsLoading: false,
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
