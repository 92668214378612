const executeSingleAction = async (
  { commit, rootGetters, dispatch, state },
  data
) => {
  commit('SET_EXECUTE_SINGLE_ACTION_LOADING', true);
  try {
    const { workflow } = state;
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = `/proxy/execute-single-action`;
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    data.customer_id = customer_id;
    if (workflow?.isServerless) {
      data.isServerless = workflow?.isServerless;
    }
    const response = await window.axios.post(url, data, { timeout: 300000 });

    if (!response || !response.data || !response.data.success) {
      throw response.data;
    }
    return response.data;
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
    return error;
  } finally {
    // stuff here
    commit('SET_EXECUTE_SINGLE_ACTION_LOADING', false);
  }
};

export default executeSingleAction;
