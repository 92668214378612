const fetchAppointmentOptions = async ({ commit, dispatch, rootGetters }) => {
  commit("SET_FETCH_APPOINTMENT_OPTIONS_LOADING", false);
  try {
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post("/proxy/get-appointment-types", {
      customer_id,
    });
    if (!response || !response.data.success) {
      throw new Error(response.data.message);
    }
    commit("SET_APPOINTMENT_OPTIONS", response.data.types);
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    commit("SET_FETCH_APPOINTMENT_OPTIONS_LOADING", false);
  }
};

export default fetchAppointmentOptions;
