import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
    tools: [],
    tool: {
        id: '',
        customer_id: '',
        workflow_id: '',
        name: '',
        type: '',
        description: '',
        input_schema: {
            type: 'object',
            properties: {},
            required: []
        },
        WorkflowTool: {},
    },

    // loaders
    createToolLoading: false,
    updateToolLoading: false,
    deleteToolLoading: false,
    fetchToolLoading: false,
    fetchToolsLoading: false,
});

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters,
};
