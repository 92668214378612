const deleteModel = async ({ commit, rootGetters, dispatch }, modelId) => {
    commit("SET_DELETE_MODEL_LOADING", true);
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];

        const data = {
            id: modelId,
            customer_id: customer.customer_id,
        };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        const response = { data: { success: true } }
        await window.axios.delete("/proxy/models", {
           data,
        });

        if (response && response.data.success) {
            commit("DELETE_MODEL", modelId);
            dispatch("notifications/pushNotification", "Deleted", { root: true });
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    } finally {
        commit("SET_DELETE_MODEL_LOADING", false);
    }
};

export default deleteModel;
