const clearWorkflow = ({ commit }) => {
  commit('SET_AUTOMATION_WORKFLOW', null);
  commit('SET_SELECTED_NODE', null);
  commit('SET_START_SETTINGS', {
    period: null,
    time_at: null,
    day: null,
    week: [],
    trigger_subtype: null,
    trigger_type: 'cron',
  });
  commit('WORKFLOW_EDITED', false);
  commit('SET_NODES', []);
};

export default clearWorkflow;
