import Vue from 'vue';

const mutations = {
  ADD_WORKER: (state, payload) => {
    state.workers = [
      ...state.workers,
      {
        ...payload,
        count: payload.workflowIds ? payload.workflowIds.length : 0,
      },
    ];
  },
  SET_WORKER: (state, payload) => {
    state.worker = payload;
  },
  SET_WORKERS: (state, payload) => {
    state.workers = payload.map((item) => ({
      ...item,
      count: item.workflowIds?.length || 0,
    }));
  },
  DELETE_WORKER: (state, id) => {
    state.workers = state.workers.filter((item) => item.id !== id);
  },
  SET_CREATE_WORKER_LOADING: (state, payload) => {
    state.createWorkerLoading = payload;
  },
  SET_EDIT_WORKER_LOADING: (state, payload) => {
    state.editWorkerLoading = payload;
  },
  SET_DELETE_WORKER_LOADING: (state, payload) => {
    state.deleteWorkerLoading = payload;
  },
  SET_FETCH_WORKER_LOADING: (state, payload) => {
    state.fetchWorkerLoading = payload;
  },
  SET_FETCH_WORKERS_LOADING: (state, payload) => {
    state.fetchWorkersLoading = payload;
  },
  SET_RUNNING_WORKER: (state, payload) => {
    state.runningWorker = payload;
  },
  SET_INPUTS: (state, payload) => {
    state.worker.inputs = payload;
  },
  SET_OUTPUT: (state, payload) => {
    if (payload.model) {
      state.worker.model = payload.model;
    }
    if (payload.action) {
      Vue.set(state.worker, 'action', payload.action);
    }
    if (payload.taskId) {
      Vue.set(state.worker, 'taskId', payload.taskId);
    }
  },
  SET_WORKER_LOGS: (state, payload) => {
    Vue.set(state.worker, 'logs', payload);
  },
};

export default mutations;
