import Cookies from 'js-cookie';

import router from '@/router';

const signOut = ({ commit }) => {
  commit('SET_AUTH', false);
  commit('customer/SET_CUSTOMER', null, { root: true });

  Cookies.remove('token');
  Cookies.remove('customerId');
  Cookies.remove('userId');
  Cookies.remove('is_privileged');

  if (router.currentRoute.name !== 'login') {
    router.push('/login');
  }
};

export default signOut;
