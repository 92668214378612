const searchPatients = async ({ rootGetters, dispatch }, { search, limit }) => {
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = { customer_id, search, limit };
    const response = await window.axios.post(
      '/proxy/get-customer-patients',
      data
    );
    if (response && response.data.success) {
      return response.data.patients;
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
    return [];
  }
};

export default searchPatients;
