const mutations = {
    SET_TOOLS: (state, payload) => {
        state.tools = payload;
    },
    SET_TOOL: (state, payload) => {
        if(typeof payload.input_schema === 'string') {
            payload.input_schema = JSON.parse(payload.input_schema);
        }
        state.tool = payload
    },
    SET_TOOLS_LOADING: (state, payload) => {
        state.fetchToolsLoading = payload;
    },
    SET_FETCH_TOOL_LOADING: (state, payload) => {
        state.fetchToolLoading = payload;
    },
    SET_TOOL_WORKFLOW: (state, payload) => {
        state.tool.WorkflowTool = payload;
        state.tool.workflow_id = payload.id;
    },
    SET_CREATE_TOOL_LOADING: (state, payload) => {
        state.createToolLoading = payload;
    },
    SET_UPDATE_TOOL_LOADING: (state, payload) => {
        state.updateToolLoading = payload;
    },
    SET_DELETE_TOOL_LOADING: (state, payload) => {
        state.deleteToolLoading = payload;
    },
    SET_FETCH_TOOLS_LOADING: (state, payload) => {
        state.fetchToolsLoading = payload;
    },
}

export default mutations;