import cloneDeep from 'lodash/cloneDeep';
import { workflowStatus } from '@/util/workflow-statuses';

const getWorkflowStatus = async (
  { rootGetters, getters, dispatch, commit },
  { storage_id, firstCall, workflowId, isServerless }
) => {
  const running = cloneDeep(getters['WORKFLOW_RUNNING_OBJ'](workflowId));
  if (running.workflow_status === workflowStatus.NOT_STARTED && !firstCall) {
    return;
  }
  commit('SET_CHECK_AUTOMATION_WORKFLOW_STATUS_LOADING', true);

  const customer = rootGetters['customer/CUSTOMER'];
  const inputTaskQuestions = getters['INPUT_TASK_QUESTIONS'];

  const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

  let customer_id = customer.customer_id;
  if (customer_id === 0) {
    customer_id = selected_customer.customer_id;
  }

  try {
    const data = {
      customer_id: customer_id,
      extended: isServerless ? false : true,
      storage_id,
    };

    const response = await window.axios.post(
      '/proxy/get-workflow-status',
      data
    );

    if (!response.data.success) {
      throw response.data.message;
    } else {
      const { workflow_status, WorkflowTasks, human_in_the_loop } =
        response.data.workflow;

      const workflowIdle = workflow_status === workflowStatus.IDLE;
      const workflowPending = workflow_status === workflowStatus.PENDING;

      running.workflow_status = workflow_status;
      running.actions_statuses = WorkflowTasks;
      running.storageId = storage_id;

      if (human_in_the_loop && !inputTaskQuestions) {
        commit('SET_INPUT_TASK_QUESTIONS', human_in_the_loop);
      }

      if (!running.PAUSE_UPDATES && (workflowIdle || workflowPending)) {
        setTimeout(() => {
          dispatch('getWorkflowStatus', {
            storage_id,
            firstCall: false,
            workflowId,
            isServerless
          });
        }, 2000);
      }
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
    running.workflow_status = workflowStatus.FAILURE;
  } finally {
    commit('SET_WORKFLOW_RUNNING_OBJ', { ...running, workflowId });
    commit('SET_CHECK_AUTOMATION_WORKFLOW_STATUS_LOADING', false);
  }
};

export default getWorkflowStatus;
