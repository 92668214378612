import createPromt from "./createPromts";
import deletePromt from "./deletePromts";
import fetchPromts from "./fetchPromts";
import fetchPromt from "./fetchPromt";
import updatePromt from "./updatePromt";

export default {
  createPromt,
  deletePromt,
  fetchPromts,
  fetchPromt,
  updatePromt,
};
