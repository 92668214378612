const getStorageStats = async (
  { commit, rootGetters, dispatch },
  { id, storage_id }
) => {
  try {
    commit("SET_AUTOMATION_WORKFLOW_STATS", {
      id,
      storage_id,
      loading: true,
    });
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];

    let url = "/proxy/generate-report";

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = { customer_id, storage_id };

    const response = await window.axios.post(url, data);

    if (response && response.data.success) {
      commit("SET_AUTOMATION_WORKFLOW_STATS", {
        id,
        storage_id,
        stats: response.data.stats,
        loading: false,
      });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  }
};

export default getStorageStats;
