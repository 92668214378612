const getConnectorInputOutput = async (
  { rootGetters, dispatch, commit },
  { action_uuid, storage_id, iteration }
) => {
  try {
    commit('SET_CONNECTOR_DETAILS_LOADING', true);
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];

    let url = "/proxy/connector-input-output";

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = { customer_id, storage_id, action_uuid, iteration };

    const response = await window.axios.post(url, data);

    if (response && response.data.success) {
      commit('SET_CONNECTOR_DETAILS', response.data.connector_details)
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    commit('SET_CONNECTOR_DETAILS_LOADING', false);
  }
};

export default getConnectorInputOutput;
