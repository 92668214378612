const approveNote = async ({ commit, dispatch }, { noteId, codes, triggerWorkflow }) => {
    commit('SET_APPROVE_NOTE_LOADING', true)
    try {
        let url = `/proxy/patient-notes/${noteId}/codes`;
        const response = await window.axios.put(url, {
            codes,
            triggerWorkflow,
        });
        if(!response.data.sucess) {
            throw response.data;
        }
    } catch (e) {
        dispatch('notifications/setError', e.message, { root: true });
    } finally {
        commit('SET_APPROVE_NOTE_LOADING', false)
    }
}

export default approveNote