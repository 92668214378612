import Cookies from 'js-cookie';

import router from '@/router';
import { setAuthToken } from '@/util/setAuthToken';

const verifyToken = async ({ commit, dispatch }, { otp }) => {
  commit('SET_VERIFYING_TOKEN', true);
  commit('SET_VERIFY_TOKEN_ERROR', false);

  try {
    const user_id = Cookies.get('user_id');
    const customer_id = Cookies.get('customer_id');

    const { data } = await window.axios.post('/proxy/verify-otp', {
      customer_id,
      user_id,
      otp,
    });

    if (data && data.success === true) {
      setAuthToken(data, commit);
      router.push({ path: '/' });
      dispatch('notifications/pushNotification', 'Token Verified', {
        root: true,
      });
    } else {
      throw data;
    }
  } catch (error) {
    commit('SET_VERIFY_TOKEN_ERROR', true);
    commit('SET_VERIFY_TOKEN_MESSAGE', error.message);
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_VERIFYING_TOKEN', false);
  }
};

export default verifyToken;
