import Cookies from 'js-cookie';

const resendToken = async ({ commit, dispatch }) => {
  try {
    commit('SET_RESENDING_TOKEN', true);
    const user_id = Cookies.get('user_id');
    const customer_id = Cookies.get('customer_id');

    const { data } = await window.axios.post('/proxy/resend-otp', {
      customer_id,
      user_id,
    });

    if (data && data.success === true) {
      dispatch('notifications/pushNotification', 'Token has been sent.', {
        root: true,
      });
    } else {
      throw data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_RESENDING_TOKEN', false);
  }
};

export default resendToken;
