const getCustomerUsers = async ({ commit, getters, dispatch }) => {
  commit('SET_CUSTOMER_USERS_LOADING', true);

  try {
    const customer = getters['CUSTOMER'];
    const selectedCustomer = getters['SELECTED_CUSTOMER'];
    let response;

    if (customer && customer.customer_id === 0) {
      response = await window.axios.post('/proxy/get-users', {
        customer_id: selectedCustomer.customer_id,
      });
    } else {
      response = await window.axios.get('/proxy/get-users');
    }

    if (response.data.success) {
      commit('SET_CUSTOMER_USERS', response.data.users);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_CUSTOMER_USERS_LOADING', false);
  }
};

export default getCustomerUsers;
