import toggleSidebar from './toggleSidebar';
import toggleHeader from './toggleHeader';
import postError from './postError';
import getServiceTypes from './getServiceTypes';

export default {
  toggleSidebar,
  toggleHeader,
  postError,
  getServiceTypes,
};
