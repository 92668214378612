const getStat = async ({ commit, rootGetters, dispatch }, { storageId }) => {
  commit('SET_ACTIVITY_STAT_LOADING', true);

  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = "/proxy/generate-report";
    let customer_id = customer.customer_id;

    if (customer_id === 0) {
      const fakeCustomerID = selected_customer.customer_id;
      if (fakeCustomerID) customer_id = fakeCustomerID;
    }

    const response = await window.axios.post(url, {
      customer_id,
      storage_id: storageId,
    });

    if (response && response.data.success) {
      commit('SET_ACTIVITY_STATS', { activityId: storageId, stats: response.data.stats });
      return response.data.stats
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_ACTIVITY_STAT_LOADING', false);
  }
};

export default getStat;
