import copyWorkflow from './copyWorkflow';
import updateWorkflow from './updateWorkflow';
import fetchWorkflows from './fetchWorkflows';
import deleteWorkflow from './deleteWorkflow';
import setWorkflowOwners from './setWorkflowOwners';
import googleAction from './googleAction';
import outlookAction from './outlookAction';
import twilioAction from './twilioAction';
import sftpAction from './sftpAction';
import exportWorkflow from './exportWorkflow';
import fetchWorkflowsNames from "./fetchWorkflowsNames";

export default {
  copyWorkflow,
  updateWorkflow,
  fetchWorkflows,
  deleteWorkflow,
  setWorkflowOwners,
  googleAction,
  outlookAction,
  twilioAction,
  sftpAction,
  exportWorkflow,
  fetchWorkflowsNames,
};
