const chargeActions = async ({ commit, rootGetters, dispatch }, options) => {
  commit('SET_CHARGE_UPDATE_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let url = '/proxy/invoices/charge-action';
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    const { balance_operation_id, patient_id, action, visit_id, total_charge } =
      options;

    url += `?customer_id=${customer_id}&patient_id=${patient_id}&action=${action}`;

    if (visit_id) url += `&visit_id=${visit_id}`;
    if (total_charge) url += `&total_charge=${total_charge}`;
    if (balance_operation_id)
      url += `&balance_operation_id=${balance_operation_id}`;

    const response = await window.axios.put(url);

    if (response && response.data.success) {
      dispatch('notifications/pushNotification', 'Invoice Updated.', {
        root: true,
      });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_CHARGE_UPDATE_LOADING', false);
  }
};

export default chargeActions;
