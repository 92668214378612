import { getAutomationInitialSettings } from "@/util/browserAutomationEvents";

const clearModel = ({ commit }) => {
    const emptyModel = {
        settings: {
            automation: getAutomationInitialSettings(),
        },
    };
    commit("SET_MODEL", emptyModel);
};

export default clearModel;
