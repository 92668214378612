const uploadModelArtifacts = async ({ commit, rootGetters, dispatch }, payload) => {
    commit("SET_UPDATE_MODEL_LOADING", true);
    commit("SET_ENDPOINT_CREATION_LOADING", true);
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        const formData = new FormData();
        console.log(payload.file)
        formData.append('file', payload.file)
        const response = await window.axios.post(`/proxy/models/${payload.modelId}/artifacts`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        if (response && response.data.success) {
            const endpointResponse = await window.axios.patch(`/proxy/models/${payload.modelId}/endpoint`);
            if (endpointResponse && endpointResponse.data.success) {
                commit('SET_MODEL', endpointResponse.data.model)
            }
            commit('SET_MODEL_LOCATION_TO_UPLOAD',response.data.location)
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
        commit("SET_UPDATE_MODEL_LOADING", false);
    } finally {
        commit("SET_UPDATE_MODEL_LOADING", false);
    }
};

export default uploadModelArtifacts;
