export const fetchWebhooksTriggerTypes = async ({ commit, dispatch }) => {
  commit('SET_WEBHOOKS_LOADING', true);
  try {

    const response = await window.axios.get('/proxy/webhooks/triggers');
    if (response && response.data.success) {
      commit('SET_WEBHOOKS_TRIGGERS', response.data.triggers);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_WEBHOOKS_LOADING', false);
  }
};



