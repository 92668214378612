const SET_ERROR = (state, error) => {
  state.error = error;
};

const PUSH_NOTIFICATION = (state, payload) => {
  state.notifications = [ ...state.notifications, payload ];
};

const CLEAR_NOTIFICATION = (state, id) => {
  state.notifications = state.notifications.filter(notification => notification.id !== id);
};

const CLEAR_ALL_NOTIFICATIONS = (state) => {
  state.notifications = [];
};

export default {
  SET_ERROR,
  PUSH_NOTIFICATION,
  CLEAR_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
}
