const deletePromt = async ({ commit, rootGetters, dispatch }, promtId) => {
  commit("SET_DELETE_PROMT_LOADING", true);
  try {
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];

    const data = {
      id: promtId,
      customer_id: customer.customer_id,
    };

    if (customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.delete("/proxy/promts", {
      data,
    });

    if (response && response.data.success) {
      commit("DELETE_PROMT", promtId);
      dispatch("notifications/pushNotification", "Deleted", { root: true });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    commit("SET_DELETE_PROMT_LOADING", false);
  }
};

export default deletePromt;
