import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
    patientNotes: [],

    // loaders
    getPatientNotesLoading: false,
    approveNoteLoading: false,
});

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters,
};
