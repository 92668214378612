import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  automationWorkflows: [],
  automationWorkflowsNames: [],
  selectedAutomationWorkflow: {},
  automationWorkflowHistory: {},
  workflowsCount: 0,

  // loaders
  fetchAutomationWorkflowLoading: false,
  fetchAutomationWorkflowNamesLoading: false,
  editWorkflowLoading: {},
  setWorkflowOwnerLoading: {},
  deleteWorkflowLoading: false,
  workflowHistoryLoading: false,
  selectedAutomationWorkflowLoading: false,
  updateAutomationWorkflowLoading: false,
  performingGoogleAction: false,
  performingOutlookAction: false,
  performingTwilioAction: false,
  performingSFTPAction: false,
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
