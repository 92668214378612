const getters = {
  INVOICES: ({ invoices }) => invoices,
  FETCH_INVOICES_LOADING: ({ fetchInvoicesLoading }) => fetchInvoicesLoading,
  TOTAL_INVOICES_COUNT: ({ totalInvoicesCount }) => totalInvoicesCount,
  UNPAID_INVOICES: ({ unpaidInvoices }) => unpaidInvoices,
  INVOICE_DETAILS: ({ invoiceDetails }) => invoiceDetails,
  FETCH_INVOICE_DETAILS_LOADING: ({ fetchInvoiceDetailsLoading }) =>
    fetchInvoiceDetailsLoading,
  CHARGE_UPDATE_LOADING: ({ chargeUpdateLoading }) => chargeUpdateLoading,
  SEND_REMINDER_LOADING: ({ sendReminderLoading }) => sendReminderLoading,
  FETCH_CARDS_LOADING: ({ fetchCardsLoading }) => fetchCardsLoading,
  CARDS: ({ cards }) => cards,
  DELETE_CARD_LOADING: ({ deleteCardLoading }) => deleteCardLoading,
  PRIMARY_CARD_LOADING: ({ primaryCardLoading }) => primaryCardLoading,
  SAVE_NEW_CARD_LOADING: ({ saveNewCardLoading }) => saveNewCardLoading,
  PAYMENT_DETAILS: ({ paymentDetails }) => paymentDetails,
  AUTOPAYMENT: ({ autoPayment }) => autoPayment,
  PATIENT_BALANCE: ({ balance }) => balance,
  SAVE_PAYMENT_PLAN_LOADING: ({ savePaymentPlanLoading }) =>
    savePaymentPlanLoading,
  INVOICE_ACTIVITIES: ({ activities }) => activities,
  FETCH_ACTIVITIES_LOADING: ({ fetchActivitiesLoading }) =>
    fetchActivitiesLoading,
  FETCH_PAYMENT_PLAN_LOADING: ({ fetchPaymentPlanLoading }) =>
    fetchPaymentPlanLoading,
  PAYMENT_PLANS: ({ paymentPlans }) => paymentPlans,
  PAYMENT_PLANS_TOTAL: ({ paymentPlansTotal }) => paymentPlansTotal,
  TOGGLE_BATCH_STATUS_LOADING: ({ toggleBatchStatusLoading }) =>
    toggleBatchStatusLoading,
  CREATE_PAYMENT_PLAN_LOADING: ({ createPaymentPlanLoading }) =>
    createPaymentPlanLoading,
  STANDALONE_INVOICES: (state) => {
    return state.standaloneInvoices;
  },
  STANDALONE_INVOICES_LOADING: (state) => {
    return state.standaloneInvoicesLoading;
  },
  STANDALONE_INVOICES_TOTAL: (state) => {
    return state.standaloneInvoicesTotal;
  },
  SEND_BALANCE_TRANSACTION_LOADING: (state) => {
    return state.sendBalanceTransactionLoading;
  },
  CREATE_INVOICE_LOADING: (state) => {
    return state.createInvoiceLoading;
  },
};

export default getters;
