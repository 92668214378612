import {
  omitUIFields,
  convertToObject,
  generateTrigger,
  extractCredentials,
} from '@/util/action-types';
import { getExternalWorkflowActivatorCreds } from '@/util/workflowDetails';
import router from '@/router';
import Cookies from "js-cookie";

const saveWorkflow = async (
  { commit, rootGetters, dispatch, state },
  { loader = true } = {}
) => {
  if (loader) commit('SET_SAVE_WORKFLOW_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    const allCredentials = rootGetters['credentials/AUTHENTICATIONS'];
    const { startSettings, nodes, workflow, credentials, alertSettings, s3StorageEnabled } = state;
    const IOStorageType = workflow.isServerless && s3StorageEnabled ? 's3' : 'common'

    let url = `/proxy/create-workflow/`;
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const nodeList = omitUIFields(nodes);
    const nodesObj = convertToObject(nodeList);
    const actionCredentials = extractCredentials(nodesObj);
    const externalActivatorCredentials = getExternalWorkflowActivatorCreds(
      credentials,
      allCredentials
    );

    const newWorkflow = {
      customer_id,
      user_id: Cookies.get('userId'),
      workflowSettings: {
        enabled: false,
        trigger_type: startSettings.trigger_type,
        trigger: generateTrigger(startSettings),
        actions: nodesObj,
        name: workflow.name,
        description: workflow.description,
        credentials: [
          ...actionCredentials,
          ...externalActivatorCredentials.map((c) => c.id),
        ],
        alertSettings: alertSettings,
        isServerless: workflow.isServerless,
        IOStorageType,
      },
    };

    const response = await window.axios.post(url, newWorkflow);
    if (response && response.data.success) {
      commit('SET_AUTOMATION_WORKFLOW', response.data.new_workflow);
      dispatch('setNodes', response.data.new_workflow);
      dispatch('notifications/pushNotification', 'Workflow created.', {
        root: true,
      });
      router.replace({
        path: `/workflows/${response.data.new_workflow.id}/history`,
      });
      commit('WORKFLOW_EDITED', false);
      return { success: true, workflowId: response.data.new_workflow.id };
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
    return { success: false, message: error.message };
  } finally {
    commit('SET_SAVE_WORKFLOW_LOADING', false);
  }
};

export default saveWorkflow;
