import * as moment from "moment-timezone";

const getWorkflowVersions = async ({ commit, rootGetters, dispatch }, id) => {
    commit('SET_WORKFLOW_VERSIONS_LOADING', true);
    try {
        const customer = rootGetters['customer/CUSTOMER'];
        const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

        let url = `/proxy/get-workflows/${id}/versions`;
        let customer_id = customer.customer_id;
        if (customer_id === 0) {
            customer_id = selected_customer.customer_id;
        }

        const data = { customer_id };
        const response = await window.axios.post(url, data);

        if (response && response.data.success) {
            const { versions } = response.data;
            commit('SET_WORKFLOW_VERSIONS', versions.map((version) => {
                const date =  moment(version.version_id.split('_')[0]).tz(moment.tz.guess()).format("YYYY-MM-DD, h:mm:ss A");
                return {
                    id: version.version_id,
                    name: date + ' by  ' +  version.version_id.split('_')[1],
                }
            }));
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch('notifications/setError', error.message, { root: true });
    } finally {
        // stuff here
        commit('SET_WORKFLOW_VERSIONS_LOADING', false);
    }
};

export default getWorkflowVersions;
