import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  devices: [],
  selectedDevice: {},
  selectedDeviceStatus: {},

  devicesLoading: false,
  selectedDeviceLoading: false,
  selectedDeviceStatusLoading: false,

  addUpdateDeviceLoading: false,
  removeDeviceLoading: false,
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
