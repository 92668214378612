const getPatientsAutopay = async (
  { commit, rootGetters, dispatch },
  options
) => {
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const { pid } = options;
    const data = { customer_id, pid };
    const response = await window.axios.post('/proxy/get-autopay-dates', data);

    if (response && response.data.success) {
      commit('SET_AUTOPAYMENT', response.data.batches);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  }
};

export default getPatientsAutopay;
