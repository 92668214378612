import router from "@/router";

const createWorker = async ({ commit, rootGetters, dispatch }, payload) => {
  commit("SET_CREATE_WORKER_LOADING", true);
  try {
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
    const data = { ...payload };

    if (customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post("/proxy/workers", data);
    if (response && response.data.success) {
      router.replace({ path: `/workers/${response.data.worker.id}` });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    commit("SET_CREATE_WORKER_LOADING", false);
  }
};

export default createWorker;
