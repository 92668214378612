import getCustomerData from './getCustomerData';
import getLoggedInUser from './getLoggedInUser';
import addCustomerUser from './addCustomerUser';
import getCustomerUsers from './getCustomerUsers';
import deleteCustomerUser from './deleteCustomerUser';
import deleteGreenwayAlias from './deleteGreenwayAlias';
import updateCustomerMappings from './updateCustomerMappings';
import fetchCustomerMappings from './fetchCustomerMappings';
import fetchAllCustomers from './fetchAllCustomers';
import getFeatureFlags from './getFeatureFlags';
import toggleTwoFactor from './toggleTwoFactor';

export default {
  getCustomerData,
  getLoggedInUser,
  addCustomerUser,
  getCustomerUsers,
  deleteCustomerUser,
  fetchCustomerMappings,
  deleteGreenwayAlias,
  updateCustomerMappings,
  fetchAllCustomers,
  getFeatureFlags,
  toggleTwoFactor,
};
