import { fetchWebhooks } from './fetchWebhooks';
import { deleteWebhook } from './deleteWebhook';
import { createWebhook } from './createWebhook';
import { updateWebhook } from './updateWebhook';
import { fetchWebhooksTriggerTypes } from './fetchTriggerTypes';

export default {
  fetchWebhooks,
  deleteWebhook,
  createWebhook,
  updateWebhook,
  fetchWebhooksTriggerTypes,
};