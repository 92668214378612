const fetchAllCustomers = async ({ commit, dispatch }) => {
  try {
    const response = await window.axios.get("/proxy/get-all-customers");

    if (response && response.data.success) {
      commit("SET_CUSTOMERS", response.data.customers);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  }
};

export default fetchAllCustomers;
