const getActivity = async (
  { commit, rootGetters, dispatch },
  { from, to, report_type, tz, page, limit, search }
) => {
  commit('SET_ACTIVITY_LOADING', true);
  commit('SET_ACTIVITY', []);

  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = '/proxy/get-recent-activity';
    let customer_id = customer.customer_id;

    if (customer_id === 0) {
      const fakeCustomerID = selected_customer.customer_id;
      if (fakeCustomerID) customer_id = fakeCustomerID;
    }

    const response = await window.axios.post(url, {
      customer_id,
      from,
      to,
      tz,
      report_type,
      page,
      limit,
      search
    });

    if (response?.data?.success) {
      if (report_type === 'running workflows') {
        commit('SET_RUNNING_WORKFLOWS', response.data.stats);
      } else if (report_type === 'failed workflows') {
        commit('SET_FAILED_WORKFLOWS', response.data.stats);
      } else {
        commit('SET_ACTIVITY', response.data.stats.storages);
        commit('SET_ACTIVITY_TOTAL_NUMBER', response.data.stats.totalCount);
      }
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_ACTIVITY_LOADING', false);
  }
};

export default getActivity;
