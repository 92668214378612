import router from '@/router';

const copyWorkflow = async ({ commit, rootGetters, dispatch }, workflow) => {
  commit('SET_FETCH_AUTOMATION_WORKFLOWS_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = `/proxy/copy-workflow/`;
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = {
      customer_id,
      workflow_id: workflow.id,
    };

    const response = await window.axios.post(url, data);

    if (response && response.data.success) {
      const search = router.currentRoute.query.search;
      const page = router.currentRoute.query.page;
      const archived = router.currentRoute.query.archived;
      dispatch('fetchWorkflows', { search, page, archived });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    // stuff here
    commit('SET_FETCH_AUTOMATION_WORKFLOWS_LOADING', false);
  }
};

export default copyWorkflow;
