const toggleTwoFactor = async ({ commit, rootGetters, dispatch }) => {
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const user = rootGetters['customer/USER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let customer_id = customer?.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = { user_id: user.id, customer_id };
    const response = await window.axios.put('/proxy/toggle-twofactor', data);
    commit('SET_USER', { ...user, enabled2FA: !user.enabled2FA });
    if (response && response.data.success) {
      const user = rootGetters['customer/USER'];
      commit('SET_USER', { ...user, enabled2FA: !user.enabled2FA });
      dispatch('notifications/pushNotification', 'Authentication successful', {
        root: true,
      });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  }
};

export default toggleTwoFactor;
