const fetchModel = async ({ commit, rootGetters, dispatch }, modelId) => {
    commit("SET_FETCH_MODEL_LOADING", true);
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        let url = `/proxy/models/${modelId}`;
        let customer_id = customer.customer_id;

        if (customer_id === 0) {
            customer_id = selected_customer.customer_id;
        }
        url += `?customer_id=${customer_id}`;

        const response = await window.axios.get(url);

        if (response && response.data.success) {
            commit("SET_MODEL", response.data.model);
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    } finally {
        commit("SET_FETCH_MODEL_LOADING", false);
    }
};

export default fetchModel;
