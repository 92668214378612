const fetchDeviceDetails = async ({ commit, rootGetters, dispatch }, { uid }) => {
  commit('SET_SELECTED_DEVICE_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = '/proxy/device-manager';
    let customer_id = customer.customer_id;

    if (customer_id === 0) {
      const fakeCustomerID = selected_customer.customer_id;
      if (fakeCustomerID) customer_id = fakeCustomerID
    }

    url += `?customer_id=${customer_id}`;
    url += `&device_id=${uid}`;

    const response = await window.axios.get(url);

    if (response && response.data.success) {
      commit('SET_SELECTED_DEVICE', response.data.device);
      return response.data.device
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_SELECTED_DEVICE_LOADING', false);
  }
};

export default fetchDeviceDetails;
