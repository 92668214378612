const getServiceTypes = async ({ commit, dispatch }) => {
  // commit('SET_CUSTOMER_USERS_LOADING', true);

  try {
    const response = await window.axios.get('/proxy/get-service-types');

    if (response && response.data.success) {
      commit('SET_SERVICE_TYPES', response.data.output);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    // commit('SET_CUSTOMER_USERS_LOADING', false);
  }
};

export default getServiceTypes;
