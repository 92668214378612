import axios from 'axios';
import Cookies from 'js-cookie';
import store from '@/store';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] =
  'application/x-www-form-urlencoded';

window.axios.interceptors.request.use((config) => {
  const token = Cookies.get('token');

  if (token) {
    config.headers.set('Authorization', `Bearer ${token}`);
    if(config.headers.get('Content-Type') === 'multipart/form-data') {
      return config
    }
    config.headers.set('Content-Type', 'application/json');
  }

  return config;
});

function successHandler(response) {
  return response;
}

async function errorHandler(error) {
  const data = error?.response?.data;
  const status = error?.response?.status;
  if (status === 401 && data.message === 'Unauthorized') {
    delete window.axios.defaults.headers.common['Authorization'];
    await store.dispatch('auth/signOut', { root: true });
    window.location.reload();
  }
  return Promise.reject(error);
}

window.axios.interceptors.response.use(successHandler, errorHandler);
