const getPatientCards = async ({ commit, rootGetters, dispatch }, options) => {
  commit('SET_FETCH_CARDS_LOADING', true);
  try {
    commit('SET_CARDS', []);
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    const { pid } = options;

    let url = `/proxy/api/payment/get-cards-by-patient`;
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    const response = await window.axios.post(url, { pid });

    if (response && response.data.success) {
      commit('SET_CARDS', response.data.items);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_FETCH_CARDS_LOADING', false);
  }
};

export default getPatientCards;
