import Cookies from 'js-cookie';

import router from '@/router';
import { setAuthToken } from '@/util/setAuthToken';

const signIn = async ({ commit, dispatch }, { email, password }) => {
  commit('SET_IS_AUTHENTICATING', true);
  commit('SET_AUTHENTICATION_ERROR', false);

  try {
    const { data } = await window.axios.post(
      '/proxy/login',
      {
        email,
        password,
      },
      { withCredentials: true }
    );

    if (data && data.success === true) {
      if (!data?.token && data?.user?.enabled2FA) {
        Cookies.set('user_id', data?.user?.id);
        Cookies.set('customer_id', data?.user?.customer_id);
        router.push({ path: '/two-factor' });
      } else {
        setAuthToken(data, commit);
        router.push({ path: '/' });
        dispatch(
          'notifications/pushNotification',
          'Authentication successful',
          { root: true }
        );
      }
    } else {
      throw data;
    }
  } catch (error) {
    commit('SET_AUTHENTICATION_ERROR', true);
    commit('SET_AUTHENTICATION_MESSAGE', error.message);
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_IS_AUTHENTICATING', false);
  }
};

export default signIn;
