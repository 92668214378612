import Cookies from "js-cookie";

const updateUserSettings = async ({ commit, rootGetters, dispatch }, payload) => {
    commit("SET_USER_SETTINGS_LOADING", true);
    commit("SET_CURRENT_TOOL_SET_LOADING", true);
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload, userId: Cookies.get('userId'), customer_id: customer.customer_id };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        const response = await window.axios.put(`/proxy/ai/settings/${data.userId}`, data);
        if (response && response.data.success) {
            commit('SET_USER_SETTINGS', response.data.settings)
            if(response.data.ToolSet) {
                commit('SET_CURRENT_TOOL_SET', response.data.ToolSet)
            }
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    } finally {
        commit("SET_USER_SETTINGS_LOADING", false);
        commit("SET_CURRENT_TOOL_SET_LOADING", false);
    }
};

export default updateUserSettings;