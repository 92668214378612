import actions from "@/store/ml-models/actions";
import mutations from "@/store/ml-models/mutations";
import getters from "@/store/ml-models/getters";

const state = () => ({
    models: [],
    endpoints: [],
    model: {
        id: '',
        name: '',
        artifacts: '',
        modelDataLocation: '',
        endpoint: '',
        inputType: 'application/json', // json|file
        input: {
            fields: [],
        },
        output: {}
    },
    output: {},
    locationToUpload: '',

    fetchModelsLoading: false,
    fetchModelLoading: false,
    deleteModelLoading: false,
    createModelLoading: false,
    editModelLoading: false,
    fetchEndpointsLoading: false,
    runningModel: false,
    endpointCreationLoading: false,
})

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters,
};