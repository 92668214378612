const mutations = {
  SET_AUTOMATION_WORKFLOW: (state, payload) => {
    state.workflow = payload;
  },
  SET_FETCH_AUTOMATION_WORKFLOW_LOADING: (state, payload) => {
    state.fetchWorkflowLoading = payload;
  },
  SET_SELECTED_NODE: (state, payload) => {
    state.selectedNode = payload;
  },
  SET_SELECTED_NODE_OPTIONS: (state, payload) => {
    state.selectedNodeOptions = payload;
  },
  SET_START_SETTINGS: (state, payload) => {
    state.startSettings = {
      ...state.startSettings,
      ...payload,
    };
  },
  ADD_CREDENTIAL: (state, payload) => {
    state.credentials = [...state.credentials, payload];
  },
  DELETE_CREDENTIAL: (state, id) => {
    state.credentials = state.credentials.filter((credId) => credId !== id);
  },
  SET_CREDENTIALS: (state, payload) => {
    state.credentials = payload;
  },
  SET_OUTPUT_MODELS: (state, payload) => {
    state.actionsOutputs = payload;
  },
  SET_NODES: (state, payload) => {
    state.nodes = payload;
  },
  SET_COPY_OF_SAVED_NODES_NODES: (state, payload) => {
    state.copyOfSavedNodes = payload;
  },
  SET_MOVING_NODE_ID: (state, payload) => {
    state.movingNodeId = payload;
  },
  EDIT_WORKFLOW_PROPERTY: (state, { key, value }) => {
    state.workflow = {
      ...state.workflow,
      [key]: value,
    };
  },
  WORKFLOW_EDITED: (state, payload = true) => {
    state.workflowEdited = payload;
  },
  SET_INPUT_TASK_QUESTIONS: (state, payload) => {
    state.inputTaskQuestions = payload;
  },
  SET_SET_INPUT_TASK_LOADING: (state, payload) => {
    state.fetchWorkflowLoading = payload;
  },
  SET_SAVE_WORKFLOW_LOADING: (state, payload) => {
    state.saveWorkflowLoading = payload;
  },
  SET_UPDATE_WORKFLOW_UPDATING: (state, payload) => {
    state.updateWorkflowLoading = payload;
  },
  SET_FETCH_OUTPUT_MODELS_LOADING: (state, payload) => {
    state.fetchOutputModelsLoading = payload;
  },
  SET_APPOINTMENT_OPTIONS: (state, payload) => {
    state.appointmentOptions = payload;
  },
  SET_FETCH_APPOINTMENT_OPTIONS_LOADING: (state, payload) => {
    state.fetchAppointmentOptionsLoading = payload;
  },
  SET_WORKFLOW_RUNNING_OBJ: (state, payload = {}) => {
    const { workflowId, ...running } = payload;

    state.runStatus = {
      ...state.runStatus,
      [workflowId]: {
        ...state.runStatus[workflowId],
        ...running,
      },
    };
  },
  SET_START_WORKFLOW_LOADING: (state, payload) => {
    state.startWorkflowLoading = payload;
  },
  SET_RUN_AUTOMATION_WORKFLOW_LOADING: (state, payload) => {
    state.runWorkflowLoading = payload;
  },
  SET_CHECK_AUTOMATION_WORKFLOW_STATUS_LOADING: (state, payload) => {
    state.checkingAutomationWorkflowStatusLoading = payload;
  },
  SET_EXECUTE_SINGLE_ACTION_LOADING: (state, payload) => {
    state.executeSingleActionLoading = payload;
  },
  SET_ALERT_SETTINGS: (state, payload) => {
    state.alertSettings = payload;
  },
  SET_S3_STORAGE_ENABLED: (state, payload) => {
    state.s3StorageEnabled = payload;
  },
  SET_WORKFLOW_VERSIONS: (state, payload) => {
    state.workflowVersions = payload;
  },
  SET_WORKFLOW_VERSIONS_LOADING: (state, payload) => {
    state.workflowVersionsLoading = payload;
  }
};

export default mutations;
