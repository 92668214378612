const deleteCard = async ({ commit, rootGetters, dispatch }, options) => {
  commit('SET_DELETE_CARD_LOADING', options.cardID);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let url = '/proxy/api/payment/delete-card';
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    const { pid, cardID } = options;
    const response = await window.axios.post(url, { pid, cardID });

    if (response && response.data.success) {
      dispatch('notifications/pushNotification', 'Card Deleted.', {
        root: true,
      });
      dispatch('invoices/getPatientCards', { pid }, { root: true });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_DELETE_CARD_LOADING', false);
  }
};

export default deleteCard;
