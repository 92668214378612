import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
    sessionsList: [],
    toolSets: [],
    systemPrompts: [],
    currentToolSet: {},
    currentSession: {},
    userSettings: {},
    editSession: {},
    editSystemPrompt: {},
    editToolSet: {},
    sendMessageData: undefined,
    chatWorkspace: {},

    sessionsListLoading: false,
    toolSetsLoading: false,
    userSettingsLoading: false,
    AIResponseLoading: false,
    currentSessionLoading: false,
    currentToolSetLoading: false,
    createToolSetLoading: false,
    systemPromptLoading: false,
    updateSystemPromptsLoading: false,
    sendMessageLoading: false,
});

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters,
};
