import cloneDeep from 'lodash/cloneDeep';

const deleteGreenwayAlias = async ({ commit, getters, dispatch, state }, data) => {
  try {
    const customerMappings = getters['CUSTOMER_MAPPINGS'];

    const response = await window.axios.delete('/proxy/delete-greenway-alias', {
      data,
    });

    if (response && response.data.success) {
      const mapping = customerMappings[data.mapIndex];
      mapping.greenway_aliases.splice(data.aliasIndex, 1);
      state.customerMappings[data.mapIndex] = mapping;

      commit('SET_CUSTOMER_MAPPINGS', cloneDeep(state.customerMappings));
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  }
};

export default deleteGreenwayAlias;
