import { getAutomationInitialSettings } from "@/util/browserAutomationEvents";

const clearWorker = ({ commit }) => {
  const emptyWorker = {
    settings: {
      automation: getAutomationInitialSettings(),
    },
  };
  commit("SET_WORKER", emptyWorker);
};

export default clearWorker;
