import Cookies from "js-cookie";

const getSessions = async ({ commit, rootGetters, dispatch }, payload) => {
    commit("SET_SESSIONS_LIST_LOADING", true);
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload, userId: Cookies.get('userId'), customer_id: customer.customer_id };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        let url = `/proxy/ai/sessions?page=${data.page}&customer_id=${data.customer_id}&limit=${data.limit || 20}`;
        url += `&userId=${Cookies.get('userId')}`;

        const response = await window.axios.get(url, data);
        if (response && response.data.success) {
            if(payload.push) {
                commit('PUSH_SESSION_LIST', response.data.sessions)
            } else {
                commit('SET_SESSIONS_LIST', response.data.sessions)
            }
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    } finally {
        commit("SET_SESSIONS_LIST_LOADING", false);
    }
};

export default getSessions;