import getWorkspace from "@/store/ai-workspace/actions/getWorkspace";
import updateWorkspace from "@/store/ai-workspace/actions/updateWorkspace";
import getWorkspaceSessions from "@/store/ai-workspace/actions/getWorkspaceSessions";
import getWorkspaces from "@/store/ai-workspace/actions/getWorkspaces";
import createWorkspace from "@/store/ai-workspace/actions/createWorkspace";
import updateParticipants from "@/store/ai-workspace/actions/updateParticipants";
import updateWorkspacePrompt from "@/store/ai-workspace/actions/updateWorkspacePrompt";
import getWorkspacePrompts from "@/store/ai-workspace/actions/getWorkspacePrompts";
import deleteWorkspacePrompt from "@/store/ai-workspace/actions/deleteWorkspacePrompt";
import createWorkspacePrompt from "@/store/ai-workspace/actions/createWorkspacePrompt";

const actions = {
    getWorkspaces,
    getWorkspace,
    getWorkspaceSessions,
    updateWorkspace,
    createWorkspace,
    updateParticipants,
    updateWorkspacePrompt,
    getWorkspacePrompts,
    deleteWorkspacePrompt,
    createWorkspacePrompt,
}

export default actions;