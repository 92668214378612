const getFeatureFlags = async ({ commit, rootGetters, dispatch }) => {
  try {
    commit('SET_GET_FEATURE_FLAGS_LOADING', true);
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let customer_id = customer?.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }
    const url = `/proxy/customers/${customer_id}/feature-flags`;
    const response = await window.axios.get(url);

    if (response.data.success) {
      commit('SET_FEATURE_FLAGS', response.data.flags);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_GET_FEATURE_FLAGS_LOADING', false);
  }
};

export default getFeatureFlags;
